import React from 'react';
import * as ThemeUI from 'theme-ui';
import { Link as ReactLink } from 'react-router-dom';
import { Box } from 'components/layout';

export const Link = ({ to, children, sx, onClick, disabled }) => {
  if (disabled) {
    return (
      <Box
        sx={{ cursor: 'not-allowed', color: 'primary-disabled', ...sx }}
      >
        {children}
      </Box>
    );
  } 
  return (
    <ThemeUI.Link
      as={ReactLink}
      sx={{ textDecoration: 'none', color: 'text-primary', ...sx }}
      to={to}
      onClick={onClick}
    >
      {children}
    </ThemeUI.Link>
  );
};
