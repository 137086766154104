import React from 'react';
import { Flex } from 'components/layout';

export const SearchColumn = ({ children }) => (
  <Flex
    sx={{
      flexDirection: 'column',
      gap: '16px',
      width: '100%',
    }}
  >
    {children}
  </Flex>
);
