import { layout } from './layout';
import { forms } from './forms';
import { buttons } from './buttons';
import { text } from './text';
import { cards } from './cards';

export const variants = {
  layout,
  forms,
  buttons,
  text,
  cards,
};
