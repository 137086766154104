import React from 'react';
import { Flex } from 'components/layout';

export const CustomerCard = ({ children, isDisabled }) => (

  <Flex
    flexDirection="column"
    sx={{
      pointerEvents: isDisabled && 'none',
      opacity: isDisabled && '0.5',
    }}
  >
    {children}
  </Flex>
);
