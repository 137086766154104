import React from 'react';
import ReactDatePicker from 'react-datepicker';
import styles from './styles.module.scss';

export const DatePicker = ({ 
  id, 
  selectedDate,
  minDate,
  maxDate,
  isOpen, 
  isInline, 
  onChange, 
}) => (
  <ReactDatePicker
    calendarContainer={({ className, children }) => (
      <div
        className={`${className} ${styles.datePicker}`}
      >
        {children}
      </div>
    )}
    open={isOpen}
    inline={isInline}
    id={id}
    selected={selectedDate}
    minDate={minDate}
    maxDate={maxDate}
    onChange={onChange}
  />
);
