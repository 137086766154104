import { breakpointsToArrayIndex } from 'global/theming/foundations/breakpoints';

export function breakpoints(breakpoints) {
  const breakpointArray = [];

  const breakpointKeys = Object.keys(breakpointsToArrayIndex);
  breakpointKeys.forEach((breakpointKey) => {
    breakpointArray[breakpointsToArrayIndex[breakpointKey]] = breakpoints[breakpointKey];
  });

  return breakpointArray;
}
