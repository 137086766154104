import React from 'react';
import { Flex } from 'components/layout';
import { MenuWithIcon, TextMenu } from './components';

export const HeaderMenu = ({ children }) => (
  <Flex
    gap="60px"
    alignItems="center"
  >
    {children}
  </Flex>
);

HeaderMenu.MenuWithIcon = MenuWithIcon;
HeaderMenu.TextMenu = TextMenu;
