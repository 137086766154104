import React from 'react';
import {IconButton} from "../IconButton";

export const AddSquare = ({ size = 20, sx }) => (
    <IconButton
        as="div"
        sx={sx}
    >
    <svg
        style={{ width: size }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
    >
        <path className="a"
              d="M20.5,1.8A1.3,1.3,0,0,0,19.2.5H1.8A1.3,1.3,0,0,0,.5,1.8V19.2a1.3,1.3,0,0,0,1.3,1.3H19.2a1.3,1.3,0,0,0,1.3-1.3ZM5.283,11.37a.87.87,0,1,1,0-1.739h4.13a.217.217,0,0,0,.217-.217V5.283a.87.87,0,1,1,1.739,0v4.13a.217.217,0,0,0,.217.217h4.13a.87.87,0,1,1,0,1.739h-4.13a.217.217,0,0,0-.217.217v4.13a.87.87,0,1,1-1.739,0v-4.13a.217.217,0,0,0-.217-.217Z"
              transform="translate(-0.5 -0.5)"/>
    </svg>
    </IconButton>
);
