import { Auth } from 'aws-amplify';
import { Routing } from 'global/routing';

export async function logout() {
  try {

    await Auth.signOut();
    window.location = Routing.LOGIN_PAGE;
    
  } catch (err) {
    console.log('error signing out:', err);
  }
}
