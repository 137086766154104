import React from 'react';
import { IconButton } from '../IconButton';

export const Billing = ({ size = 14, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 18.667"
    >
      <g transform="translate(-3)">
        <path className="BillingA" d="M12.7,15.5a.194.194,0,0,0-.194.194v6.222a.194.194,0,0,0,.194.194h4.083a2.333,2.333,0,0,0,2.333-2.333V15.694a.194.194,0,0,0-.194-.194Zm1.749,1.944h2.333a.389.389,0,1,1,0,.778H14.445a.389.389,0,1,1,0-.778Zm0,1.556h2.333a.389.389,0,1,1,0,.778H14.445a.389.389,0,1,1,0-.778Z" transform="translate(-2.111 -3.444)"/>
        <path className="BillingA" d="M9.611,15.694a.194.194,0,0,0-.194-.194H3.194A.194.194,0,0,0,3,15.694v4.083a2.333,2.333,0,0,0,2.333,2.333H9.417a.194.194,0,0,0,.194-.194ZM5.057,17.717a.389.389,0,0,1,.551-.551l.754.754a.194.194,0,0,0,.275,0l.754-.754a.389.389,0,1,1,.551.551l-.754.754a.194.194,0,0,0,0,.275l.754.754a.389.389,0,0,1-.551.551L6.637,19.3a.194.194,0,0,0-.275,0l-.754.754a.389.389,0,0,1-.551-.551l.754-.754a.194.194,0,0,0,0-.275Z" transform="translate(0 -3.444)"/>
        <path className="BillingA" d="M15.833,5.083a.583.583,0,0,0,.583-.583V3.333a.583.583,0,0,0-1.167,0V4.5A.583.583,0,0,0,15.833,5.083Z" transform="translate(-2.722 -0.611)"/>
        <path className="BillingA" d="M14.667,0H5.333A2.333,2.333,0,0,0,3,2.333v8.75a.194.194,0,0,0,.194.194H9.417a.194.194,0,0,0,.194-.194V5.25a.194.194,0,0,0-.194-.194H4.75a.194.194,0,0,1-.194-.194V2.333a.778.778,0,0,1,.778-.778h9.333a.778.778,0,0,1,.778.778V4.861a.194.194,0,0,1-.194.194H10.583a.194.194,0,0,0-.194.194v5.833a.194.194,0,0,0,.194.194h6.222A.194.194,0,0,0,17,11.083V2.333A2.333,2.333,0,0,0,14.667,0Zm-7,8.944H7.083a.194.194,0,0,0-.194.194v.583a.389.389,0,1,1-.778,0V9.139a.194.194,0,0,0-.194-.194H5.333a.389.389,0,1,1,0-.778h.583a.194.194,0,0,0,.194-.194V7.389a.389.389,0,0,1,.778,0v.583a.194.194,0,0,0,.194.194h.583a.389.389,0,1,1,0,.778Zm7,0H12.333a.389.389,0,1,1,0-.778h2.333a.389.389,0,1,1,0,.778Z"/>
      </g>
    </svg>
  </IconButton>
);
