import React from 'react';
import { DatePicker, LabeledElement } from 'components/form-elements';
import { Popup } from 'components/Popup';
import { LabeledDateTrigger } from './components';

export const LabeledDatePicker = ({ id, label, selectedDate, minDate, onChange }) => (
  <LabeledElement
    label={label}
    id={id}
  >
    <Popup
      trigger={(
        <div id={id}>
          <LabeledDateTrigger
            selectedDate={selectedDate}
            onChange={onChange}
          />
        </div>
      )}
    >

      <DatePicker
        isInline
        selectedDate={selectedDate}
        minDate={minDate}
        onChange={selectedDate => onChange(selectedDate)}
      />
    </Popup>
  </LabeledElement>
);
