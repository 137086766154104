import React from 'react';
import { IconButton } from 'components/icons/IconButton';

export const ChevronRight = ({ size = 11, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 17"
    >

      <defs>
        <style>
          {`.ChevronRightA{fill:rgba(0,0,0,0);stroke:#AEAEAE;stroke-miterlimit:10;stroke-width:3px;}`}
        </style>
      </defs>

      <path 
        className="ChevronRightA"
        d="M2,6.274l7.837,7.71,7.836-7.71" 
        transform="translate(-5.205 18.725) rotate(-90)"
      />
    </svg>
  </IconButton>
);
