import React from 'react';
import { IconButton } from '../IconButton';

export const PencilEdit = ({ size = 24, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >

      <path className="a"
            d="M13.862,8.226a.514.514,0,0,0-.707,0L7.263,14.118a.523.523,0,0,0-.111.168L6.036,17.078a.5.5,0,0,0,.11.539.51.51,0,0,0,.54.11l2.791-1.116a.483.483,0,0,0,.168-.111l5.893-5.892a.5.5,0,0,0,0-.707Z"
            transform="translate(0 0)"/>
      <path className="a"
            d="M16.58,5.5h0a1.677,1.677,0,0,0-1.191.493l-.838.838a.5.5,0,0,0,0,.707l1.675,1.675a.5.5,0,0,0,.707,0l.838-.837A1.685,1.685,0,0,0,16.58,5.5Z"
            transform="translate(0 0)"/>
      <path className="a" d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Z"
            transform="translate(0 0)"/>
    </svg>
  </IconButton>
);
