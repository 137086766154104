import React from 'react';
import { Modal } from 'components/modals';
import { Text } from 'components/typography';
import { Box, Flex } from 'components/layout';
import { Button } from 'components/form-elements';
import { useModal } from 'store/modals';
import { styles } from './styles';

export const PopupModal = ({ title, description, onRetry }) => {
  const { closeModal } = useModal();

  const onTryAgain = () => {
    onRetry();
    closeModal();
  };

  return (
    <Modal
      sx={styles.popupModal}
      closeOnOutsideClick={false}
    >
      <Text
        as="h1"
        fontSize={32}
        textAlign="center"
        textTransform="capitalize"
      >
        {title}
      </Text>

      <Text
        sx={{ width: '250px' }}
        fontSize={20}
        fontWeight="bold"
        textAlign="center"
      >
        {description}
      </Text>

      <Flex sx={styles.modalActions}>
        <Button
          variant="text"
          onClick={closeModal}
        >
          CANCEL
        </Button>

        <Box
          sx={{
            width: '2px',
            backgroundColor: 'bg.600',
          }}
        />

        <Button
          variant="text"
          onClick={onTryAgain}
        >
          TRY AGAIN
        </Button>
      </Flex>
    </Modal>
  );
};
