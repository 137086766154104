import React from 'react';
import { Card, Flex } from 'components/layout';
import Accordion from 'components/Accordion';
import { Table } from 'components/Table';
import { formatDate, YEAR_MONTH_MONTHDAY } from 'utils/time-and-date';
import { columns, mapDataToAccessor } from './tableData';

export const CustomerMaintenanceCard = ({ orders }) => {

  const getData = (order) => {
    const data = [];
    for (const [, value] of Object.entries(order.description)) {
      data.push(mapDataToAccessor(value[0]));
    }
    return data;
  };

  const getTitle = (date) => `Created: ${formatDate(new Date(date), YEAR_MONTH_MONTHDAY)}`;

  return (
    <Card
      variant="primary"
    >
      <Flex
        flexDirection="column"
        gap="5px"
        sx={{
          width: '100%',
        }}
      >
        {orders?.map((order, i) => (
          <Accordion
            order={order}
            title={getTitle(order.created)}
            key={i}
          >
            <Table
              data={getData(order)}
              columns={columns}
              showExportTable={false}
              showToggleColumns={false}
              showTotalResults={false}
              typeOrder
            />
          </Accordion>
        ))}
      </Flex>
    </Card>
  ); 
};
