import React from 'react';
import { Flex } from 'components/layout';

export const Form = ({ children, sx, onSubmit }) => (
  <Flex
    as="form"
    sx={sx}
    autoComplete="off"
    onSubmit={e => {
      e.preventDefault();
      onSubmit();
    }}
  >
    {children}
  </Flex>
);
