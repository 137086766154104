import React from 'react';
import { createContainer } from 'unstated-next';

function useUserState() {

  const [user, setUser] = React.useState(null);
  const [role, setRole] = React.useState(null);

  return {
    user,
    role,
    setUser,
    setRole,
  };
}

const User = createContainer(useUserState);

export const UserProvider = User.Provider;
export const useUser = User.useContainer;
export const useUserRole = User.useContainer;
