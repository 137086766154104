import React from 'react';
import { Flex } from 'components/layout';
import { TabButton } from './components';

export const TabMenu = ({ tabs }) => (
  <Flex
    justifyContent="center"
    gap={10}
    sx={{ marginBottom: '12px' }}
  >
    {tabs.map((tab, i) => (
      <TabButton
        key={i}
        isDisabled={tab.isDisabled}
      >
        {tab.title}
      </TabButton>
    ))}

  </Flex>
);
