import React from 'react';
import { IconButton } from '../IconButton';

export const Assurance = ({ size = 17.564, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.564 17.194"
    >
      <path className="AssuranceA" d="M17.561,10.5a3.288,3.288,0,0,0-2.2-3.1V6.837a6.586,6.586,0,1,0-13.171,0v.557a3.293,3.293,0,0,0,0,6.211,1.1,1.1,0,0,0,1.463-1.039V6.837a5.122,5.122,0,0,1,10.244,0v5.729a1.093,1.093,0,0,0,.366.8v.416c0,1.079-.945,1.463-1.829,1.463h-1.3a1.463,1.463,0,1,0,0,1.463h1.3a2.99,2.99,0,0,0,3.293-2.927v-.35A3.293,3.293,0,0,0,17.561,10.5Z" transform="translate(0.004 -0.251)"/>
    </svg>
  </IconButton>
);
