export const awsconfig = {
  Auth: {
    region: process.env.REACT_APP_region,
    userPoolId: process.env.REACT_APP_userPoolId,
    userPoolWebClientId: process.env.REACT_APP_clientId,
    oauth: {
      domain: process.env.REACT_APP_hostedUIDomain,
      scope: ['openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_redirectSignIn,
      redirectSignOut: process.env.REACT_APP_redirectSignOut,
      responseType: 'code',
    },
  },
};
