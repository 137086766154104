import { authorize, postRequest } from 'utils/http';
import { Environment } from 'global/environment';
import { mapDataForBackend } from './mapData';

export async function inviteUsersAPI(users) {
  return postRequest(
    `${Environment.BACKEND_API}/admin/users/invite`,
    mapDataForBackend(users),
    await authorize(),
  );
}
