import React from 'react';
import { IconButton } from 'components/icons/IconButton';

export const Excel = ({ size = 30, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.483 29.927"
    >
      <path
            d="M29.915,3.99H19.7V5.88h3.01V8.87H19.7v.953h3.01v2.994H19.7V13.8h3.01v2.831H19.7v1.134h3.01V20.6H19.7v1.134h3.01v2.854H19.7v2.082H29.915c.161-.048.3-.239.4-.569a2.785,2.785,0,0,0,.164-.808V4.336c0-.163-.056-.26-.164-.295A1.4,1.4,0,0,0,29.915,3.99ZM28.591,24.584h-4.9V21.733h4.9v2.851Zm0-3.986h-4.9V17.76h4.9Zm0-3.973h-4.9V13.807h4.9v2.818Zm0-3.81h-4.9V9.825h4.9v2.991h0Zm0-3.962h-4.9V5.882h4.9V8.854ZM0,3.519V27.145l17.985,3.111V.329L0,3.529ZM10.66,21.391q-.1-.278-.963-2.366c-.57-1.392-.914-2.2-1.015-2.434H8.651L6.722,21.183l-2.578-.174L7.2,15.293,4.4,9.578,7.03,9.439l1.738,4.472H8.8l1.962-4.675,2.717-.171L10.246,15.25l3.334,6.31-2.92-.171Z"
            transform="translate(0 -0.328)"/>
    </svg>
  </IconButton>
);
