import React from 'react';
import { Text } from 'components/typography';
import { MenuWithIcon } from '../MenuWithIcon';

export const TextMenu = ({ children, isDisabled, icon }) => (
  <MenuWithIcon
    isDisabled={isDisabled}
    icon={icon}
  >
    <Text
      variant="header.menu"
      isFaded={isDisabled}
    >
      {children}
    </Text>
  </MenuWithIcon>
);
