import React from 'react';
import { Box, Flex } from 'components/layout';
import { Button } from 'components/form-elements';
import { useModal } from 'store/modals';
import { useActivateUser, useDeactivateUser, useUpdateUserRole } from 'services/user';
import { useUsers } from 'services/users';

export function UserActions({ user }) {
  const { openModal } = useModal();
  const { users } = useUsers();
  const { activateUser } = useActivateUser();
  const { deactivateUser } = useDeactivateUser();
  const { updateUserRole } = useUpdateUserRole();

  const handleMethod = (type) => {
    switch (type) {
      case 'revoke':
        return deactivateUser(user);
      case 'activate':
        return activateUser(user);
      case 'user':
      case 'admin':
        return updateUserRole(user);
      default:
        return null;
    }
  };

  const handleAction = (type) => {
    let message;
    switch (type) {
      case 'admin':
        message = `Are you sure you what to set ${user.username} as an admin?`;
        break;
      case 'user':
        message = `Are you sure you what to set ${user.username} as a user?`;
        break;
      case 'revoke':
        message = `Are you sure you what to deactivate ${user.username}?`;
        break;
      case 'activate':
        message = `Are you sure you what to activate ${user.username}?`;
        break;
      default:
        message = '';
    }

    openModal({
      modalType: 'ConfirmationModal',
      modalProps: {
        title: message,
        onConfirm: () => handleMethod(type),
      },
    });

  };

  const isLastAdmin = () => user.role === 'admin'
      && user.status === 'active'
      && users.filter(item => item.tenantId === user.tenantId
                && item.role === user.role
                && item.status === user.status).length === 1;

  return (
    <Box
      sx={{
        minWidth: '200px',
      }}
    >
      <Flex
        flexDirection="column"
      >
  
        {
          user.status === 'active' 
          && (
          <Button
            variant="dropdown"
            isDisabled={isLastAdmin()}
            onClick={() => handleAction(user.role === 'admin' ? 'user' : 'admin')}
          >
            {user.role === 'admin' ? 'Set as user' : 'Set as admin'}
          </Button>
          )
        }

        <Button
          variant="dropdown"
          isDisabled={isLastAdmin()}
          onClick={() => handleAction(user.status === 'active' ? 'revoke' : 'activate')}
        >
          {user.status === 'active' ? 'Revoke access' : 'Activate access'}
        </Button>
      </Flex>
    </Box>
  );
}
