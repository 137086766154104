import { useMutation } from 'react-query';
import { useModal } from 'store/modals';
import { updateCustomerAPI } from './api';

export function useUpdateCustomer({ onSuccess }) {
  const { openModal } = useModal();

  const { mutate, isLoading, isError } = useMutation(
    (customer) => updateCustomerAPI(customer),
    {
      onError: (error, customer) => openModal({
        modalType: 'PopupModal',
        modalProps: {
          title: 'Unable to update the customer info',
          description: 'An error occurred while updating the customer info.',
          onRetry: () => mutate(customer),
        },
      }),
      onSuccess: (response, customer) => onSuccess(response, customer),
    },
  );

  return {
    updateCustomer: mutate,
    isLoading,
    isError,
  };
}
