import React, { useRef, useEffect } from 'react';

export const ScrollIntoView = ({ children, shouldScroll }) => {

  const $refForScroll = useRef(null);

  useEffect(() => {
    if (shouldScroll && $refForScroll.current) {
      $refForScroll.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [shouldScroll]);

  return (
    <div ref={$refForScroll}>
      {children}
    </div>
  );
};
