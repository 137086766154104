import React from 'react';
import * as ThemeUI from 'theme-ui';

export const Text = ({
  as = 'span',
  fontWeight,
  fontSize,
  textAlign,
  textTransform,
  variant,
  sx,
  isFaded,
  children,
}) => (
  <ThemeUI.Text
    as={as}
    sx={{
      fontWeight,
      fontSize,
      textAlign,
      textTransform,
      color: isFaded ? 'primary-disabled' : 'text-primary',
      ...sx,
    }}
    variant={variant}
  >
    {children}
  </ThemeUI.Text>
);
