import React from 'react';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';

const padding = '10px 15px';
export const CustomerCardPrimaryInfo = ({ info }) => (
  
  <Flex
    sx={{
      backgroundColor: 'primary',
      padding,
    }}
  >
    <Text
      variant="card.label"
      sx={{
        color: 'bg.0',
      }}
    >
      {info}
    </Text>
  </Flex>
);
