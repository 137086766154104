import React from 'react';
import * as Icons from 'components/icons';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';
import { formatCurrency } from 'utils/format';

export const columns = [
  {
    Header: 'Type',
    accessor: 'typeName',
  },
  {
    Header: 'Unit',
    accessor: 'unitName',
  },
  {
    Header: 'Residual',
    accessor: 'residual',
    Footer: ({ rows }) => {
      const totalOrderValue = React.useMemo(
        () => formatCurrency(rows.reduce((prev, curr) => prev + curr.values.residual, 0)),
        [rows],
      );

      return (
        <Flex
          sx={{
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <Icons.Sigma sx={{ fill: 'white' }} />

          <Text
            sx={{
              color: 'white',
            }}
          >
            {totalOrderValue}
          </Text>
        </Flex>
      );
    },
    Cell: ({ value }) => formatCurrency(value),
  },
];

export const mapDataToAccessor = order => ({
  typeName: order.typename,
  unitName: order.unitname,
  residual: order.residual,
});
