import React from 'react';
import { Box, Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { Button } from 'components/form-elements';
import { Modal } from 'components/modals/Modal';
import { useModal } from 'store/modals';

export const TableModal = ({
  firstColumnHeader,
  secondColumnHeader,
  firstColumnData,
  secondColumnData,
  onClose,
}) => {
  const { closeModal } = useModal();

  const handleConfirm = () => {
    closeModal();
    onClose();
  };
  
  return (
    <Modal closeOnOutsideClick={false}>
      <Box
        sx={{
          backgroundColor: 'bg.0',
          width: '600px',
        }}
      >
        <Grid 
          columns={2} 
          gap={20}
        >
          {firstColumnData.length > 0 && (
            <Flex
              flexDirection="column"
              sx={{
                padding: '20px',
              }}
            >
              <Text
                sx={{
                  paddingTop: '10px',
                  fontWeight: 'bold',
                }}
              >
                {firstColumnHeader}
              </Text>
              {firstColumnData?.map((data, i) => (
                <Text
                  key={i}
                  sx={{
                    paddingTop: '10px',
                  }}
                >
                  {data}
                </Text>
              ))}
            </Flex>
          )}
          {secondColumnData.length > 0 && (
            <Flex
              flexDirection="column"
              sx={{
                padding: '20px',
              }}
            >
              <Text
                sx={{
                  paddingTop: '10px',
                  fontWeight: 'bold',
                }}
              >
                {secondColumnHeader}
              </Text>

              {secondColumnData?.map((data, i) => (
                <Text
                  key={i}
                  sx={{
                    paddingTop: '10px',
                  }}
                >
                  {data}
                </Text>
              ))}
            </Flex>
          )}
        </Grid>
        <Flex
          flexDirection="column"
          gap="50px"
          sx={{
            padding: '30px',
          }}
        >
          <Flex
            sx={{
              width: '100%',
            }}
            justifyContent="flex-end"
          >
            <Button 
              variant="secondary" 
              onClick={() => handleConfirm()}
            >
              Close
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};
