import React from 'react';
import * as ThemeUI from 'theme-ui';
import { isFlexGapSupported } from 'utils/browser-support';

export const Flex = ({ 
  className,
  sx,
  as,
  flexDirection,
  justifyContent,
  alignContent,
  alignItems,
  gap,
  children,
  ...props
}) => {
  let flexSx = {
    gap,
    flexDirection,
    justifyContent,
    alignContent,
    alignItems,
    ...sx,
  };

  // Workaround for browsers that do not support flexbox gap yet
  if (!isFlexGapSupported && flexSx.gap) {
    if (flexSx.flexDirection === 'column') {
      flexSx = {
        ...flexSx,
        '& > * + *': {
          marginTop: `${flexSx.gap}!important`,
        },
      };
    } else {
      flexSx = {
        ...flexSx,
        '& > * + *': {
          marginLeft: `${flexSx.gap}!important`,
        },
      };
    }
  }

  return (
    <ThemeUI.Flex
      className={className}
      sx={flexSx}
      as={as}
      {...props}
    >
      {children}
    </ThemeUI.Flex>
  );
};
