import React, { useState } from 'react';
import { Container, Flex } from 'components/layout';
import { ShouldRender } from 'components/ShouldRender';
import { Customer, Search, SearchResults } from './components';

export const Home = () => {
  const [searchedFilters, setSearchedFilters] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  return (
    <Flex
      sx={{
        justifyContent: 'center',
      }}
    >
      <Container variant="page.content">
        <Search
          setSearchedFilters={setSearchedFilters}
          setSelectedCustomer={setSelectedCustomer}
        />

        <SearchResults
          searchedFilters={searchedFilters}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
        />

        <ShouldRender when={!!selectedCustomer}>
          <Customer
            selectedCustomer={selectedCustomer}
            setSelectedCustomer={setSelectedCustomer}
          />
        </ShouldRender>
      </Container>
    </Flex>
  );
};
