import React from "react";
import { IconButton } from "../IconButton";

export const PlusIcon = ({ size = 24, sx }) => (
  <IconButton
    as="div"
    sx={{
      fill: "none",
      stroke: "primary",
      strokeLinecap: "round",
      strokeWidth: "3px",
    }}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 33"
    >
      <g transform="translate(-1.5 -1.5)">
        <path d="M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z" />
        <path d="M18,12V24" />
        <path d="M12,18H24" />
      </g>
    </svg>
  </IconButton>
);
