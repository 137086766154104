import React from 'react';
import { IconButton } from '../IconButton';

export const Product = ({ size = 20.012, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.012 19.838"
    >
      <g transform="translate(-0.295 -0.397)">
        <path className="ProductA" d="M17.75,2.474h-10a.931.931,0,0,0-1,.84.931.931,0,0,0,1,.84h9.5a.465.465,0,0,1,.5.42v6.639a.078.078,0,0,0,.03.061l2.75,2.311a.562.562,0,0,1,0,.891.854.854,0,0,1-1.061,0L17.4,12.733a1.4,1.4,0,0,0-1.57.308.925.925,0,0,0,.108,1.352l2.744,2.353a.192.192,0,0,1,.072.147v1.177a4.6,4.6,0,0,0,.94,2.021.255.255,0,0,0,.175.1,4.516,4.516,0,0,0,3.786-1.169.182.182,0,0,0,.026-.227l-.9-1.462a.189.189,0,0,1-.028-.1V13.451c.034-1.213-1.359-2.319-2.912-3.43a.2.2,0,0,1-.088-.16V4.155a1.558,1.558,0,0,0-.587-1.189A2.208,2.208,0,0,0,17.75,2.474Z" transform="translate(-3.403)"/>
        <path className="ProductA" d="M6.25,17.6h10a.929.929,0,0,0,1-.836.929.929,0,0,0-1-.836h-9a.465.465,0,0,1-.5-.418V9.755a.206.206,0,0,0-.125-.181.7.7,0,0,1-.155-.1l-2.75-2.3a.567.567,0,0,1-.232-.62.7.7,0,0,1,.551-.459.844.844,0,0,1,.741.2L6.75,7.935V7.911A1.38,1.38,0,0,0,8.222,7.5a.916.916,0,0,0-.156-1.292L5.322,3.864a.192.192,0,0,1-.072-.146V2.547A4.573,4.573,0,0,0,4.31.541a.259.259,0,0,0-.175-.1A4.534,4.534,0,0,0,.349,1.607a.18.18,0,0,0-.026.226l.9,1.455a.19.19,0,0,1,.028.1V7.147c-.037,1.338,1.672,2.541,3.411,3.762a.2.2,0,0,1,.089.16v5.272a1.158,1.158,0,0,0,.437.89,1.66,1.66,0,0,0,1.062.37Z" transform="translate(0 0)"/>
      </g>
    </svg>
  </IconButton>
);
