import React from 'react';
import { IconButton } from 'components/icons/IconButton';

export const Compass = ({ size = 18, sx }) => (
    <IconButton
        as="div"
        sx={sx}
    >
        <svg
            style={{ width: size }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path className="a"
                  d="M24,12A12,12,0,1,0,12,24,12,12,0,0,0,24,12ZM2.08,13.545a.49.49,0,0,1,.484-.565H3.918a.98.98,0,0,0,0-1.96H2.564a.49.49,0,0,1-.484-.565A10,10,0,0,1,3.9,6.08a.49.49,0,0,1,.742-.057l.955.955A.98.98,0,1,0,6.978,5.592l-.956-.954a.493.493,0,0,1-.142-.385.488.488,0,0,1,.2-.357A9.98,9.98,0,0,1,10.455,2.08a.49.49,0,0,1,.565.484V3.918a.98.98,0,0,0,1.96,0V2.564a.49.49,0,0,1,.565-.484,10.06,10.06,0,0,1,8.375,8.375.49.49,0,0,1-.484.565H20.082a.98.98,0,0,0,0,1.96h1.354a.49.49,0,0,1,.484.565A10,10,0,0,1,20.1,17.92a.49.49,0,0,1-.742.057l-.955-.955a.98.98,0,1,0-1.386,1.386l.956.954a.493.493,0,0,1,.142.385.488.488,0,0,1-.2.357,9.98,9.98,0,0,1-4.375,1.816.49.49,0,0,1-.565-.484V20.082a.98.98,0,0,0-1.96,0v1.354a.49.49,0,0,1-.565.484A10.06,10.06,0,0,1,2.08,13.545Z"/>
            <path className="a"
                  d="M17.739,6.3A.981.981,0,0,0,16.7,6.049L9.953,8.477A2.439,2.439,0,0,0,8.477,9.952L6.049,16.7a.98.98,0,0,0,1.3,1.237l6.542-2.726a2.438,2.438,0,0,0,1.32-1.319L17.936,7.35A.979.979,0,0,0,17.739,6.3ZM12,13.714A1.714,1.714,0,1,1,13.714,12,1.714,1.714,0,0,1,12,13.714Z"/>
        </svg>
    </IconButton>
);
