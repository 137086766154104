import React from 'react';
import { Input } from 'components/form-elements';

export const SearchBar = ({ keyword, setKeyword, placeholder }) => (
  <Input
    sx={{
      width: '250px',
    }}
    placeholder={placeholder}
    value={keyword}
    onChange={(e) => {
      setKeyword(e.target.value);
    }}
  />
);
