import React from 'react';
import { Flex } from 'components/layout';
import { breakpoints } from 'utils/breakpoints';

export const MenuContainer = ({ children, isSelected, isDisabled, icon }) => (
  <Flex
    alignItems="center"
    gap="15px"
    sx={{
      position: 'relative',
      height: '53px',
      width: breakpoints({ _: '244px', xl: '263px' }),
      backgroundColor: 'bg.0',
      paddingLeft: '25px',
      paddingRight: '28px',
      cursor: isDisabled && 'not-allowed',
      borderLeft: isSelected && '5px solid',
    }}
  >

    {React.cloneElement(icon, {
      sx: {
        width: '25px',
        fill: isDisabled ? 'primary-disabled' : 'primary',
      },
    })}

    {children}
  </Flex>
);
