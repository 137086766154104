import React from 'react';
import { IconButton } from '../IconButton';

export const Enterprise = ({ size = 18.549, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.549 18.573"
    >
      <path className="EnterpriseA" d="M17.106,14.09a.192.192,0,0,1-.12-.165A5.411,5.411,0,0,0,11.6,8.886H10.242a.193.193,0,0,1-.193-.193V4.624a.194.194,0,0,1,.118-.179,2.319,2.319,0,1,0-1.782,0,.194.194,0,0,1,.118.179V8.692a.193.193,0,0,1-.193.193H6.571a5.068,5.068,0,0,0-5.007,5.039.2.2,0,0,1-.12.168,2.319,2.319,0,1,0,1.793.016.2.2,0,0,1-.116-.192,3.5,3.5,0,0,1,3.45-3.484H8.31a.193.193,0,0,1,.193.193v3.3a.194.194,0,0,1-.118.179,2.319,2.319,0,1,0,1.782,0,.194.194,0,0,1-.118-.179v-3.3a.193.193,0,0,1,.193-.193H11.6a3.859,3.859,0,0,1,3.829,3.483.193.193,0,0,1-.115.2,2.319,2.319,0,1,0,1.8-.02Z" transform="translate(-0.002 0.015)"/>
    </svg>
  </IconButton>
);
