import React from 'react';
import { IconButton } from 'components/icons/IconButton';

export const Magnify = ({ size = 30, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 29.984"
    >
      <path
            d="M29.253,25.725l-5.8-5.8a12.812,12.812,0,1,0-3.533,3.534l5.8,5.8a2.53,2.53,0,0,0,3.533,0A2.5,2.5,0,0,0,29.253,25.725ZM12.808,3.756a9.057,9.057,0,1,1-9.057,9.057,9.057,9.057,0,0,1,9.057-9.057Z"
            transform="translate(0.015 0.007)"/>
    </svg>
  </IconButton>
);
