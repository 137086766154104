import React from 'react';
import * as ThemeUI from 'theme-ui';
import { Loader } from 'components/loaders';

export const Button = ({
  type = 'button',
  id,
  ref,
  isDisabled,
  as,
  className,
  variant,
  sx,
  name,
  onClick,
  children,
  isLoading,

}) => (
  <ThemeUI.Button
    as={as || 'button'}
    className={className}
    sx={{
      padding: '0px',
      outline: 'none',
      cursor: 'pointer',

      '&:disabled': {
        cursor: 'default',
      },
      ...sx,
    }}
    variant={variant}
    id={id}
    ref={ref}
    name={name}
    type={type}
    disabled={isDisabled}
    onClick={onClick}
  >
    {isLoading ? <Loader /> : children }
  </ThemeUI.Button>
);
