export const forms = {
  input: {
    labeled: {
      width: '100%',
      height: '29px',
      padding: '0 10px',
      fontSize: '14px',
      backgroundColor: 'bg.0',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'bg.500',
      borderRadius: '5px',
      outline: 'none',

      '&:focus': {
        borderColor: 'primary',
      },
    },
  },
};
