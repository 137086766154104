import { authorize, postRequest } from 'utils/http';
import { Environment } from 'global/environment';
import { mapDataForBackend, mapDataForClient } from './mapData';

export async function searchCustomersAPI(searchFilters) {
  const mappedSearchFilters = mapDataForBackend(searchFilters);
  try {
    const customers = await postRequest(
      `${Environment.BACKEND_API}/search?limit=${1000}`,
      mappedSearchFilters,
      await authorize(),
    );
    return Promise.resolve(customers.items.map((customer, index) => mapDataForClient(customer, index)));
  } catch (error) {
    return Promise.reject(error);
  }
}

