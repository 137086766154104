import { colors } from './colors';
import { root } from './root';
import { shadows } from './shadows';
import { breakpoints } from './breakpoints';

export const foundations = {
  breakpoints,
  colors,
  shadows,
  styles: {
    root,
  },
};
