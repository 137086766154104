const mainButtonStyles = {
  width: '130px',
  height: '43px',
  textTransform: 'uppercase',
  fontSize: '17px',
  border: 'none',
  borderRadius: '0px',
};

export const buttons = {
  icon: {
    width: 'fit-content',
    height: 'fit-content',
    padding: '2px',

    '&:focus': {
      backgroundColor: 'bg.200',
      borderRadius: '100%',
      transition: 'background-color 0.2s ease',
    },
  },

  primary: {
    ...mainButtonStyles,
    backgroundColor: 'primary',
    color: 'white',

    '&:disabled': {
      backgroundColor: 'primary-disabled',
    },
  },

  secondary: {
    ...mainButtonStyles,
    backgroundColor: 'bg.800',
    color: 'white',

    '&:hover': {
      color: 'text-primary',
      backgroundColor: 'transparent',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'bg.800',
    },
  },

  text: {
    width: '100%',
    textTransform: 'uppercase',
    color: 'primary',
    backgroundColor: 'transparent',
    fontSize: '24px',
    fontWeight: 'bold',
    border: 'none',

    '&:focus': {
      textDecoration: 'underline',
    },

    inline: {
      fontSize: '14px',
      color: 'text-primary',
      backgroundColor: 'transparent',
      fontWeight: 'bold',
      border: 'none',
      textTransform: 'capitalize',

      '&:focus': {
        textDecoration: 'underline',
      },
    },
  },

  accordion: {
    backgroundColor: 'bg.250',
    height: '41px',
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    outline: 'none',
    justifyContent: 'space-between',
    transition: 'background-color 0.6s ease',
  },
  dropdown: {
    backgroundColor: 'bg.0',
    color: 'black',
    padding: '10px !important',
    textAlign: 'left',
    borderRadius: '0px',
    '&:hover': {
      backgroundColor: 'bg.300',
    },
    '&:disabled': {
      backgroundColor: 'primary-disabled',
      color: 'white',
    },
  },
};
