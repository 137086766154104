import React from 'react';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';
import { ShouldRender } from 'components/ShouldRender';
import { Button } from 'components/form-elements';
import * as Icons from 'components/icons';

export const CustomerInfoCardHeader = ({ shouldEditCustomerInfo, setShouldEditCustomerInfo }) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    sx={{
      flexShrink: '0',
      height: '50px',
      color: 'bg.0',
      backgroundColor: 'bg.800',
      paddingLeft: '15px',
      paddingRight: '44px',
    }}
  >
    <Flex
      alignItems="center"
      gap={11}
    >
      <Text
        fontSize="14px"
        fontWeight="bold"
        textTransform="upperCase"
        sx={{ 
          color: 'bg.0', 
          lineHeight: '14px', 
        }}
      >
        Customer info
      </Text>

      <ShouldRender when={!shouldEditCustomerInfo}>
        <Button
          onClick={() => setShouldEditCustomerInfo(!shouldEditCustomerInfo)}
          sx={{
            backgroundColor: 'transparent',
          }}
        >
          <Icons.PencilEdit
            sx={{ fill: 'bg.0' }}
          />
        </Button>
      </ShouldRender>
    </Flex>

    <Icons.Card
      size={37}
      sx={{ fill: 'bg.0' }}
    />
  </Flex>
);
