import React from 'react';
import * as Icons from 'components/icons';
import { formatDate, YEAR_MONTH_MONTHDAY } from 'utils/time-and-date';
import { Text } from 'components/typography';
import { Button } from 'components/form-elements';
import { forms } from 'global/theming/variants/forms';

const labeledStyles = forms.input.labeled;

const displayDate = selectedDate => formatDate(selectedDate, YEAR_MONTH_MONTHDAY);

export const LabeledDateTrigger = ({ selectedDate, onChange }) => (
  <Button
    sx={{
      display: 'flex',
      alignItems: 'center',
      ...labeledStyles,
    }}
  >
    {selectedDate ? (
      <>
        <Text fontSize={14}>
          {selectedDate && displayDate(selectedDate)}
        </Text>

        <Icons.Discard
          as="div"
          sx={{ marginLeft: 'auto' }}
          onClick={(e) => {
            e.stopPropagation();
            onChange(null);
          }}
        />
      </>
    ) : <Icons.Calendar sx={{ marginLeft: 'auto' }} />}
  </Button>
);
