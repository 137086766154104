import React from 'react';
import { IconButton } from '../IconButton';

export const Operations = ({ size = 19.954, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.954 16.918"
    >
      <g transform="translate(-0.5 -4.499)">
        <path className="OperationsA" d="M8.2,12.308a.651.651,0,0,1,.651-.651H12.1a.651.651,0,0,1,.651.651v.217a.434.434,0,0,0,.434.434H20.02a.434.434,0,0,0,.434-.434V9.271a.868.868,0,0,0-.868-.868H14.793a4.338,4.338,0,0,0-8.632,0H1.368A.868.868,0,0,0,.5,9.271v3.253a.434.434,0,0,0,.434.434H7.766a.434.434,0,0,0,.434-.434Zm2.277-6.073A2.6,2.6,0,0,1,13.043,8.4H7.911a2.6,2.6,0,0,1,2.566-2.169Z" transform="translate(0)"/>
        <path className="OperationsA" d="M12.754,16.51a2.281,2.281,0,0,1-2.732,2.233A2.342,2.342,0,0,1,8.2,16.419v-.235a.434.434,0,0,0-.434-.434H.934a.434.434,0,0,0-.434.434V22.04a.868.868,0,0,0,.868.868H19.586a.868.868,0,0,0,.868-.868V16.184a.434.434,0,0,0-.434-.434H13.227a.434.434,0,0,0-.473.434Z" transform="translate(0 -1.49)"/>
        <path className="OperationsA" d="M12.393,14.251H11.309a.434.434,0,0,0-.434.434v1.627a.976.976,0,1,0,1.952,0V14.685A.434.434,0,0,0,12.393,14.251Z" transform="translate(-1.374 -1.292)"/>
      </g>
    </svg>
  </IconButton>
);
