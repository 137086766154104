import React from 'react';
import { Box } from 'components/layout';
import * as Icons from 'components/icons';
import { ShouldRender } from 'components/ShouldRender';

const rowPadding = '13px';

export const DisplayTable = ({
  getTableProps, 
  headerGroups,
  getTableBodyProps,
  rows,
  prepareRow,
  footerGroups,
  clickedRow,
  onRowClick,
  typeOrder,
}) => {
  const hasFooterColumns = !!footerGroups[0].headers
    .filter(header => header.render('Footer').type.name !== 'emptyRenderer').length;

  const getRowBackground = (row, even) => {
    if (typeOrder) {
      // row background for service orders
      return 'bg.50';
    } if (clickedRow?.customerNumber === row.original.id) {
      return 'bg.300';
    } if (even) {
      return 'bg.100';
    } 
    return 'bg.0';
    
  };
  return (
    <Box
      sx={{
        position: 'relative',
        maxHeight: '543px', // 10 visible items when footer is shown (493px for 10 without footer)
        overflowY: 'auto',
      }}
    >
      <Box
        {...getTableProps()}
        as="table"
        sx={{
          width: '100%',
          borderCollapse: 'collapse',
          color: 'primary-text',

          '& thead tr th': {
            position: 'sticky',
            top: '0px',
          },

          '& tfoot tr td': {
            position: 'sticky',
            bottom: '0px',
          },
        }}
      >
        <Box
          as="thead"
        >
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Box
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  as="th"
                  sx={{
                    padding: rowPadding,
                    backgroundColor: 'bg.200',
                    textAlign: 'left',
                    boxShadow: '0 2px 0 rgba(0, 0, 0, 0.1)',
                  }}
                >
                  {column.render('Header')}

                  <Icons.ChevronDown
                    sx={{
                      transform: column.isSortedDesc ? 'rotate(180deg)' : 'rotate(0deg)',
                      marginLeft: '5px',
                      fill: column.isSorted ? 'primary' : 'primary-disabled',
                    }}
                  />
                </Box>
              ))}
            </tr>
          ))}
        </Box>

        <Box
          {...getTableBodyProps()}
          as="tbody"
          sx={{ boxShadow: 3 }}
        >
          {rows.map(row => {
            prepareRow(row);
            return (
              <Box
                {...row.getRowProps()}
                as="tr"
                sx={{
                  '&:nth-of-type(even)': {
                    backgroundColor: getRowBackground(row, true),
                  },
                  cursor: !typeOrder && 'pointer',
                  backgroundColor: getRowBackground(row, false),
                }}
                onClick={() => onRowClick && onRowClick(row.original)}
              >
                {row.cells.map(cell => (
                  <Box
                    {...cell.getCellProps()}
                    as="td"
                    sx={{ padding: rowPadding }}
                  >
                    {cell.render('Cell')}
                  </Box>
                ))}
              </Box>
            );
          })}
        </Box>

        <ShouldRender when={!!rows.length && hasFooterColumns}>
          <tfoot>
            {footerGroups.map(group => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map(column => (
                  <Box
                    as="td"
                    sx={{
                      height: '49px',
                      backgroundColor: typeOrder ? 'primary-light' : 'primary',
                    }}
                    {...column.getFooterProps()}
                  >
                    {column.render('Footer')}
                  </Box>
                ))}
              </tr>
            ))}
          </tfoot>
        </ShouldRender>
      </Box>
    </Box>
  );
};
