import React from 'react';
import * as Icons from 'components/icons';
import { LabeledSection } from 'components/layout';
import { useSearchCustomers } from 'services/search-customers';
import { ShouldRender } from 'components/ShouldRender';
import { Table } from 'components/Table';
import { columns, mapDataToAccessor } from './tableData';

export const SearchResults = ({ searchedFilters, selectedCustomer, setSelectedCustomer }) => {
  const { customers } = useSearchCustomers(searchedFilters);
  const data = customers ? customers.map(item => mapDataToAccessor(item)) : [];

  const onRowClick = (clickedCustomer) => {
    const customer = customers.find(customer => customer.customerNumber === clickedCustomer.id);
    setSelectedCustomer(customer?.id === selectedCustomer?.customerNumber ? null : customer);
  };

  return (
    <ShouldRender when={!!customers}>
      <LabeledSection
        icon={<Icons.Magnify />}
        label="Results"
      >
        <Table
          data={data}
          columns={columns}
          clickedRow={selectedCustomer}
          onRowClick={onRowClick}
        />
      </LabeledSection>
    </ShouldRender>
  );
};
