import React from 'react';
import { Popup } from 'components/Popup';
import { Flex } from 'components/layout';

export const Modal = ({
  isOpen = true,
  sx,
  children,
  closeOnOutsideClick,
  onClose,
}) => (
  <Popup
    isModal
    isOpen={isOpen}
    closeOnOutsideClick={closeOnOutsideClick}
    onClose={onClose}
  >
    <Flex sx={sx}>
      {children}
    </Flex>
  </Popup>
);
