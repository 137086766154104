export const text = {
  header: {
    menu: {
      textTransform: 'uppercase',
      fontSize: '14px',
    },
  },
  sideBar: {
    navMenu: {
      fontSize: '15px',
      lineHeight: '15px',
      marginBottom: '3px',
    },
  },
  card: {
    label: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
  accordion: {
    paddingLeft: '22px',
    fontWeight: 'bold',
  },
};
