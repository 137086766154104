import React from 'react';
import { IconButton } from 'components/icons/IconButton';

export const Search = ({ size = 20, sx }) => (
  <IconButton
    as="div"
    sx={{
      fill: 'none',
      stroke: 'bg.0',
      ...sx,
    }}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.081 25.081"
    >
      <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(28.581 -3.5) rotate(90)">
        <path id="Path_8239" data-name="Path 8239" d="M23.167,13.833A9.333,9.333,0,1,1,13.833,4.5,9.333,9.333,0,0,1,23.167,13.833Z" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        <path id="Path_8240" data-name="Path 8240" d="M31.5,31.5l-6.525-6.525" transform="translate(-4.333 -4.333)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      </g>
    </svg>
  </IconButton>
);
