import React from 'react';
import { Box, Flex } from 'components/layout';
import { Text } from 'components/typography';
import { Button } from 'components/form-elements';
import { Modal } from 'components/modals/Modal';
import { useModal } from 'store/modals';

export const ConfirmationModal = ({ title, onConfirm }) => {
  const { closeModal } = useModal();

  const handleConfirm = () => {
    closeModal();
    onConfirm();
  };

  return (
    <Modal
      closeOnOutsideClick={false}
    >
      <Box
        sx={{
          backgroundColor: 'bg.0',
          width: '500px',
        }}
      >
        <Flex
          flexDirection="column"
          gap="50px"
          sx={{
            padding: '30px',
          }}
        >
          <Text
            textAlign="center"
            fontSize="20px"
            fontWeight="bold"
          >
            {title}
          </Text>
        
          <Flex 
            sx={{
              width: '100%',
            }}
            justifyContent="space-evenly"
          >
            <Button
              variant="secondary"
              onClick={closeModal}
            >
              No
            </Button>
          
            <Button 
              variant="primary"
              onClick={() => handleConfirm()}
            >
              Yes
            </Button>
          </Flex>

        </Flex>
      </Box>
    </Modal>
  ); 
};
