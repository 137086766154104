import React from 'react';
import * as ReactRouter from 'react-router-dom';
import { StoreProvider } from 'store';
import { ServiceProvider } from 'services';
import { ThemeProvider } from 'global/theming';

export const GlobalProvider = ({ children }) => (
  <ReactRouter.BrowserRouter>
    <ServiceProvider>
      <StoreProvider>
        <ThemeProvider>
          {children}
        </ThemeProvider>
      </StoreProvider>
    </ServiceProvider>
  </ReactRouter.BrowserRouter>
);
