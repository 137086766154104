import React from 'react';
import { Auth } from 'aws-amplify';
import { Redirect } from 'react-router-dom';
import { Routing } from 'global/routing';
import { useUser } from 'store/user';
import { Loader } from 'components/loaders';

export const Login = () => {

  const { user } = useUser();

  async function signIn() {
    await Auth.federatedSignIn();
  }

  if (!user) {
    signIn();
    return <Loader />;
  } return <Redirect to={Routing.HOME_PAGE} />;
};
