import React from 'react';
import { IconButton } from '../IconButton';

export const Overview = ({ size = 16, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.002 16.012"
    >
      <path className="OverviewA" d="M563.707,217.7a.705.705,0,0,0-.207.5v7.789a.707.707,0,0,0,.707.707h5.657a.707.707,0,0,0,.707-.707v-7.778a.707.707,0,0,0-.707-.707l-5.657-.011A.705.705,0,0,0,563.707,217.7Z" transform="translate(-563.5 -217.493)"/>
      <path className="OverviewA" d="M582.864,217.493l-5.657.01a.707.707,0,0,0-.707.707v3.536a.707.707,0,0,0,.707.707h5.657a.707.707,0,0,0,.707-.707V218.2A.707.707,0,0,0,582.864,217.493Z" transform="translate(-567.569 -217.493)"/>
      <path className="OverviewA" d="M570.364,238.25a.705.705,0,0,0,.207-.5v-3.536a.707.707,0,0,0-.707-.707h-5.657a.707.707,0,0,0-.707.707v3.525a.707.707,0,0,0,.707.707l5.657.01A.709.709,0,0,0,570.364,238.25Z" transform="translate(-563.5 -222.445)"/>
      <path className="OverviewA" d="M582.864,227.508h-5.657a.707.707,0,0,0-.707.707v7.778a.707.707,0,0,0,.707.707l5.657-.01a.707.707,0,0,0,.707-.707v-7.768A.707.707,0,0,0,582.864,227.508Z" transform="translate(-567.569 -220.688)"/>
    </svg>
  </IconButton>
);

