import React from 'react';
import { IconButton } from 'components/icons/IconButton';

export const User = ({ size = 29, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28.906 28.906"
    >
      <g>
        <path d="M24.672,4.233A14.453,14.453,0,1,0,4.233,24.673,14.453,14.453,0,1,0,24.672,4.233M14.453.968A13.477,13.477,0,0,1,24.739,23.162a11,11,0,0,0-3.624-5,10.811,10.811,0,0,0-4.027-1.952,5.683,5.683,0,1,0-5.272,0,10.811,10.811,0,0,0-4.022,1.949,11,11,0,0,0-3.628,5A13.477,13.477,0,0,1,14.453.968" />
      </g>
    </svg>
  </IconButton>
);
