import React from 'react';
import { IconButton } from 'components/icons/IconButton';

export const Reload = ({ size = 17, sx }) => (
  <IconButton
    as="div"
    sx={{
      fill: 'primary',
      ...sx,
    }}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.986 18.008"
    >
      <path id="Icon_open-reload" data-name="Icon open-reload" d="M9,0A9,9,0,1,0,15.4,15.4l-1.621-1.621A6.758,6.758,0,1,1,8.982,2.251a6.548,6.548,0,0,1,4.7,2.048L11.233,6.753h6.753V0L15.307,2.679A8.954,8.954,0,0,0,8.982,0Z"/>
    </svg>
  </IconButton>
);
