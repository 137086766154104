import React from 'react';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';

export const LabeledText = ({ title, children, sx }) => (
  <Flex
    flexDirection="column"
    sx={{
      height: '50px',
      width: '25%',
      fontSize: '14px',
      color: 'bg.600',
      paddingRight: '20px',
      overflowWrap: 'break-word',
      ...sx,
    }}
  >
    <Text
      fontWeight="bold"
    >
      {title}
    </Text>

    {children}

  </Flex>
);
