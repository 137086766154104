import { useQuery } from 'react-query';
import { getBuildingIdsAPI } from './api';

const BUILDING_IDS_KEY = 'buildingIds';

export function useBuildingIds() {
  const { data, isLoading, isError } = useQuery(BUILDING_IDS_KEY, getBuildingIdsAPI);

  return {
    buildingIds: data ? data.buildingIds : [],
    isLoading,
    isError,
  };
}
