import React from 'react';
import * as ThemeUI from 'theme-ui';
import { NavLink as ReactNavLink } from 'react-router-dom';

export const NavLink = ({ to, children, sx }) => (
  <ThemeUI.NavLink
    as={ReactNavLink}
    sx={{ textDecoration: 'none', fontWeight: 'normal', ...sx }}
    to={to}
  >
    {children}
  </ThemeUI.NavLink>
);
