import React from 'react';
import { IconButton } from 'components/icons/IconButton';

export const ChevronDown = ({ size = 11, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 7"
    >
      <path
        d="M8.1,11.6,2.6,6.041,4.026,4.6,8.1,8.718,12.174,4.6,13.6,6.041Z"
        transform="translate(-2.6 -4.6)"
      />
    </svg>
  </IconButton>
);
