import * as yup from 'yup';

export function uniqueProperty(propertyPath, message) {
  return this.test('unique', '', function unique(list) {
    const errors = [];
    const uniqueCheck = {};

    const errorHandler = (path, index, propPath) => errors.push(
      this.createError({
        path: `${path}[${index}].${propPath}`,
        message,
      }),
    );

    list.forEach((item, index) => {
      const propertyValue = item[propertyPath];

      if (uniqueCheck[propertyValue] !== undefined) {
        errorHandler(this.path, index, propertyPath);

        if (uniqueCheck[propertyValue] !== true) {
          errorHandler(this.path, uniqueCheck[propertyValue], propertyPath);
          uniqueCheck[propertyValue] = true;
        }
      } else {
        uniqueCheck[propertyValue] = index;
      }
    });

    if (errors.length) {
      throw new yup.ValidationError(errors);
    }

    return true;
  });
}
