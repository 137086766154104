import React from 'react';
import { LabeledElement, Input } from 'components/form-elements';

export const LabeledInput = ({
  id,
  label,
  hasError,
  sx,
  ...props
}) => (
  <LabeledElement
    id={id}
    label={label}
  >
    <Input
      variant="input.labeled"
      id={id}
      sx={{
        borderColor: hasError && 'error',
        outlineColor: hasError && 'error',
        '&:focus': {
          borderColor: hasError && 'error',
          outlineColor: hasError && 'error',
        },
        ...sx,
      }}
      {...props}
    />
  </LabeledElement>
);
