import React, { useState } from 'react';
import { Card, Flex } from 'components/layout';
import { Text } from 'components/typography';
import { LabeledDatePicker } from 'components/form-elements/pickers/LabeledDatePicker';
import { formatDate, YEAR_MONTH_MONTHDAY } from 'utils/time-and-date';

export const CustomerServiceCard = () => {
  const [plannedForDate, setPlannedForDate] = useState(null);
  const currentDate = new Date();

  return (
    <Card
      variant="primary"
    >
      <Flex
        flexDirection="column"
        gap="3px"
        sx={{
          width: '35%',
        }}
      >
        <Flex
          sx={{
            textDecoration: 'underline',
          }}
        >
          <Text>
            <Text
              fontWeight="bold"
            >
              Service:
            </Text> Maintenance
          </Text>
        </Flex>

        <Text>
          Living room: Change of carpet
        </Text>
      </Flex>

      <Flex
        flexDirection="column"
        gap="3px"
        sx={{
          justifyContent: 'space-between',
          width: '35%',
        }}
      >
        <Text>
          Planned for: { plannedForDate ? formatDate(plannedForDate, YEAR_MONTH_MONTHDAY) : '2021.03.16'}
        </Text>

        <Flex>
          <Text>
            {'Contract: '}
            <Text
              fontWeight="bold"
              sx={{
                textDecoration: 'underline',
              }}
            >
              45634566
            </Text>
          </Text>
        </Flex>
      </Flex>

      <Flex
        gap="3px"
        sx={{
          width: '30%',
        }}
      >
        <LabeledDatePicker
          id="planned-for-date"
          label="Date"
          selectedDate={plannedForDate}
          minDate={currentDate}
          onChange={selectedDate => setPlannedForDate(selectedDate)}
        />
      </Flex>
    </Card>
  ); 
};
