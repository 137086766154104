import React from 'react';
import * as ThemeUI from 'theme-ui';

export const IconButton = ({
  children,
  onClick,
  sx,
  as,
}) => (
  <ThemeUI.IconButton
    as={as}
    sx={sx}
    onClick={onClick}
  >
    {children}
  </ThemeUI.IconButton>
);
