import React from 'react';
import { Flex } from 'components/layout';
import { DatePicker } from 'components/form-elements';
import { Text } from 'components/typography';
import { formatDate, WEEKDAY_MONTHDAY_MONTH } from 'utils/time-and-date';

const displaySelectedDate = selectedDate => formatDate(selectedDate, WEEKDAY_MONTHDAY_MONTH) || 'Enter Date';

export const DateRangePicker = ({ selectedDate, onChange }) => {
  const { selectedDateFrom, selectedDateTo } = selectedDate;

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '20px',
        padding: '15px 30px',
        backgroundColor: 'bg.0',
        borderRadius: '8px',
        boxShadow: '4px 0 20px rgba(0, 0, 0, 0.2)',
      }}
    >
      <Flex gap="40px">
        <DatePicker
          isInline
          selectedDate={selectedDateFrom}
          maxDate={selectedDateTo}
          onChange={selectedDate => onChange({ selectedDateFrom: selectedDate, selectedDateTo })}
        />

        <DatePicker
          isInline
          selectedDate={selectedDateTo}
          minDate={selectedDateFrom}
          onChange={selectedDate => onChange({ selectedDateFrom, selectedDateTo: selectedDate })}
        />
      </Flex>

      <Flex
        sx={{
          justifyContent: 'center',
          width: '100%',
          paddingTop: '13px',
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: 'bg.400',
        }}
      >
        <Text fontSize="14px">
          {displaySelectedDate(selectedDateFrom)} - {displaySelectedDate(selectedDateTo)}
        </Text>
      </Flex>
    </Flex>
  );
};
