import React from 'react';
import { Popup } from 'components/Popup';
import { Checkbox, Label, TextButtonWithIcon } from 'components/form-elements';
import * as Icons from 'components/icons';
import { Flex } from 'components/layout';

export const ToggleColumns = ({ allColumns }) => {
  const allColumnsExceptFirst = allColumns.filter((column, index) => index !== 0);

  return (
    <Popup
      position="bottom left"
      trigger={(
        <div>
          <TextButtonWithIcon icon={<Icons.Customize />}>
            Customize Table
          </TextButtonWithIcon>
        </div>
      )}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '10px',
          minWidth: '175px',
          backgroundColor: 'bg.0',
          padding: '10px',
          boxShadow: 2,
        }}
      >
        {allColumnsExceptFirst.map(column => (
          <Flex
            key={column.id}
            gap="10px"
            alignItems="center"
          >
            <Checkbox
              id={column.id}
              {...column.getToggleHiddenProps()}
            />

            <Label htmlFor={column.id}>
              {column.Header}
            </Label>
          </Flex>
        ))}
      </Flex>
    </Popup>
  );
};
