import React from 'react';
import * as ThemeUI from 'theme-ui';

export const Grid = ({ gap, columns, children }) => (
  <ThemeUI.Grid
    gap={gap}
    columns={columns}
  >
    {children}
  </ThemeUI.Grid>
);
