import { useMutation, useQueryClient } from 'react-query';
import { useModal } from 'store/modals';
import { deactivateUserAPI } from './api';

export function useDeactivateUser() {
  const { openModal } = useModal();
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError } = useMutation(
    (user) => deactivateUserAPI(user.userId),
    {
      onError: (error, userId) => openModal({
        modalType: 'PopupModal',
        modalProps: {
          title: 'Unable to deactivate user',
          description: 'An error occurred while deactivating the user.',
          onRetry: () => mutate(userId),
        },
      }),
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    },
  );

  return {
    deactivateUser: mutate,
    isLoading,
    isError,
  };
}
