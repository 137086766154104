import * as DateFNS from 'date-fns';

export function formatDate(date, format) {
  if (!date) {
    return null;
  }

  return DateFNS.format(date, format);
}

export const WEEKDAY_MONTHDAY_MONTH = 'E d LLL';
export const MONTHDAY_MONTHNAME_YEAR = 'd MMM, y';
export const YEAR_MONTH_MONTHDAY = 'y.MM.dd';
