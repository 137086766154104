import React from 'react';
import { Label } from 'components/form-elements';
import { Flex } from 'components/layout';

export const LabeledElement = ({ id, label, isDisabled, children }) => (
  <Flex
    sx={{
      flexDirection: 'column',
      gap: '4px',
      width: '100%',
    }}
  >
    <Label
      htmlFor={id}
      isDisabled={isDisabled}
    >
      {label}
    </Label>

    {children}
  </Flex>
);
