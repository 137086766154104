import React from 'react';
import { Text } from 'components/typography';
import { Flex } from 'components/layout';

export const Message = ({ children, isError }) => (
  <Flex
    justifyContent="center"
    sx={{ flexGrow: '1' }}
  >
    <Text
      sx={isError && { color: 'error' }}
    >
      {children}
    </Text>
  </Flex>
);
