import React from 'react';
import * as ThemeUI from 'theme-ui';
import { Text } from 'components/typography';

export const Label = ({ htmlFor, isDisabled, children }) => (
  <ThemeUI.Label
    htmlFor={htmlFor}
    sx={{
      cursor: 'pointer',
    }}
  >
    <Text
      as="span"
      fontWeight="bold"
      fontSize="14px"
      isFaded={isDisabled}
    >
      {children}
    </Text>
  </ThemeUI.Label>
);
