import React from 'react';
import { IconButton } from '../IconButton';

export const Tools = ({ size = 16.286, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.286 16.285"
    >
    <g transform="translate(0 -0.001)">
      <path className="ToolsA" d="M4.859,12.506a.679.679,0,1,0-.96.96l.24.239a.339.339,0,0,1,0,.48L.4,17.926a1.357,1.357,0,0,0,.959,2.316h0a1.346,1.346,0,0,0,.96-.4L6.057,16.1a.339.339,0,0,1,.48,0l.24.24a.679.679,0,1,0,.96-.959Z" transform="translate(-0.001 -3.956)"/>
      <path className="ToolsA" d="M17.039,4.2h.085a1.167,1.167,0,0,0,.85-.468h0l1.335-1.787a.757.757,0,0,0-.068-.983L18.5.221a.753.753,0,0,0-.962-.085L15.7,1.408a1.162,1.162,0,0,0-.5.854,1.129,1.129,0,0,0,.058.465.339.339,0,0,1-.081.349L10.019,8.234a.509.509,0,0,0,0,.719l.48.48a.509.509,0,0,0,.72,0l5.191-5.186a.339.339,0,0,1,.333-.088A1.122,1.122,0,0,0,17.039,4.2Z" transform="translate(-3.173 0)"/>
      <path className="ToolsA" d="M16.378,14.346a.339.339,0,0,1-.328-.088l-1.52-1.52a.35.35,0,0,0-.48,0l-1.439,1.437a.339.339,0,0,0,0,.48l1.52,1.521a.339.339,0,0,1,.088.328,3.058,3.058,0,0,0,3.74,3.74.339.339,0,0,0,.153-.568l-1.018-1.014a.344.344,0,0,1-.1-.2l-.12-1.08A.339.339,0,0,1,17.252,17l1.079.12a.339.339,0,0,1,.2.1l1.018,1.018a.339.339,0,0,0,.567-.153,3.054,3.054,0,0,0-3.74-3.74Z" transform="translate(-4.022 -4.064)"/>
      <path className="ToolsA" d="M3.845,6.13a.339.339,0,0,1,.328.088l1.52,1.52a.339.339,0,0,0,.48,0L7.612,6.3a.339.339,0,0,0,0-.48L6.092,4.3A.339.339,0,0,1,6,3.97,3.054,3.054,0,0,0,2.264.23.339.339,0,0,0,2.111.8L3.126,1.815a.339.339,0,0,1,.1.2l.119,1.076a.339.339,0,0,1-.375.375l-1.08-.12a.339.339,0,0,1-.2-.1L.672,2.237A.339.339,0,0,0,.1,2.389a3.054,3.054,0,0,0,3.74,3.74Z" transform="translate(0 -0.04)"/>
    </g>
    </svg>
  </IconButton>
);
