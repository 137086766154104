import React from 'react';
import { IconButton } from '../IconButton';

export const Fulfillment = ({ size = 21.351, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.351 21.319"
    >

      <g transform="translate(-0.04 -0.054)">
        <path className="MenuFulfillmentA" d="M21.5,2.733,19.349.577A1.785,1.785,0,0,0,18.086.054H7.745A1.785,1.785,0,0,0,5.96,1.839v8.738a.221.221,0,0,0,.357.179,7.954,7.954,0,0,1,1.2-.769.44.44,0,0,0,.235-.393V2.285a.446.446,0,0,1,.446-.446H17.9a.446.446,0,0,1,.315.131L20.11,3.864a.446.446,0,0,1,.131.315V16.121a.446.446,0,0,1-.446.446H11.024a.438.438,0,0,0-.371.208,11.136,11.136,0,0,1-.873,1.213.219.219,0,0,0,.165.363h10.3a1.785,1.785,0,0,0,1.785-1.785V3.994A1.785,1.785,0,0,0,21.5,2.733Z" transform="translate(-0.636)"/>
        <path className="MenuFulfillmentA" d="M10.9,11.82a.446.446,0,0,0-.365-.157,12,12,0,0,0-3.794,1,.446.446,0,0,0-.268.371c0,.023-.3,2.295-1.367,2.519a.17.17,0,0,1-.166-.17V14.144a.446.446,0,0,0-.5-.446c-.7.089-2.4,1.763-2.957,3.392a3.173,3.173,0,0,0,.089,2.523.216.216,0,0,1-.037.252L.3,21.1a.893.893,0,1,0,1.262,1.262l1.35-1.344a.225.225,0,0,1,.2-.058,3.092,3.092,0,0,0,1.4-.012,7.086,7.086,0,0,0,3.169-2.019c2.281-2.281,3.27-6.539,3.311-6.719A.446.446,0,0,0,10.9,11.82Z" transform="translate(0 -1.247)"/>
      </g>
    </svg>
  </IconButton>
);
