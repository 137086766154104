import React from 'react';
import { GlobalProvider, Routes } from 'components/app';
import { ModalContainer } from 'components/modals';
import { Flex } from 'components/layout';
import bgImage from 'assets/images/background.jpg';

const App = () => (
  <GlobalProvider>
    <Flex
      flexDirection="column"
      sx={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: 'repeat',
        minHeight: '100vh',
      }}
    >     
      <Routes />
      <ModalContainer />
    </Flex>
  </GlobalProvider>

);

export default App;
