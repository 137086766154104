import { useQuery } from 'react-query';
import { getAgreementsAPI } from './api';

const PRODUCTS_KEY = 'products';

export function useSearchProducts({ registrationNumber }) {

  const { data, isLoading, isError, isFetched } = useQuery(
    [PRODUCTS_KEY, registrationNumber],
    () => getAgreementsAPI(registrationNumber),
  );

  return {
    products: data || [],
    isLoading,
    isError,
    isEmpty: isFetched && !data?.length && !isError,
  };
}
