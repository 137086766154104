import React from 'react';
import { Button } from 'components/form-elements';
import { ShouldRender } from 'components/ShouldRender';
import { Box } from 'components/layout';

const buttonIconClassName = 'buttonIcon';

export const ButtonWithIcon = ({
  icon,
  children,
  isLoading,
  isDisabled,
  variant = 'primary',
  sx,
  ...props
}) => (
  <Button
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...sx,

      '&:focus': {
        outline: '5px auto -webkit-focus-ring-color',
      },

      [`&:hover .${buttonIconClassName}`]: {
        display: 'grid',
      },

      [`&:disabled .${buttonIconClassName}`]: {
        display: 'none',
      },
    }}
    variant={variant}
    isDisabled={isDisabled || isLoading}
    isLoading={isLoading}
    {...props}
  >
    <>
      <ShouldRender when={!!icon}>
        <Box
          className={buttonIconClassName}
          sx={{
            display: 'none',
            marginRight: '10px',
          }}
        >
          {icon}
        </Box>
      </ShouldRender>

      {children}
    </>
  </Button>
);
