import React from 'react';
import { Flex } from 'components/layout';
import { NavMenu, DropdownMenu } from './components';

export const SideBarMenu = ({ children }) => (
  <Flex
    flexDirection="column"
    gap="3px"
    sx={{
      height: 'fit-content',
      boxShadow: 2,
    }}
  >
    {children}
  </Flex>
);

SideBarMenu.NavMenu = NavMenu;
SideBarMenu.DropdownMenu = DropdownMenu;

