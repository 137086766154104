import React from 'react';
import * as ThemeUI from 'theme-ui';

export const TextArea = ({
  variant,
  sx,
  name,
  value,
  placeholder,
  onChange,
  isDisabled,
  forwardRef,
}) => (
  <ThemeUI.Textarea
    variant={variant}
    sx={sx}
    name={name}
    value={value}
    placeholder={placeholder}
    onChange={onChange}
    disabled={isDisabled}
    ref={forwardRef}
  />
);
