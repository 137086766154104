import React from 'react';
import { Message } from 'components/typography';
import { Card } from 'components/layout';
import { SkeletonLoader } from 'components/loaders';

export const CardServiceHandler = ({ children, isLoading, isError, errorMessage, isEmpty, emptyPayloadMessage }) => {
  if (isLoading) return <Card><SkeletonLoader /></Card>;

  if (isEmpty) return <Card><Message>{emptyPayloadMessage}</Message></Card>;

  if (isError) return <Card><Message isError>{errorMessage}</Message></Card>;

  return children;
};
