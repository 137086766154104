import { getAuthToken } from 'utils/auth';

export async function authorize() {

  const token = await getAuthToken();

  const authHeader = {
    Authorization: token,
  };

  return { headers: authHeader };
}
