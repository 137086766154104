import { useQuery } from 'react-query';
import { getUsersAPI } from 'services/users/get-users/api';

const USERS = 'users';

export function useUsers() {
  const { data, isLoading, isError } = useQuery(USERS, getUsersAPI);

  return {
    users: data || [],
    isLoading,
    isError,
  };
}
