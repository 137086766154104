import React from 'react';
import { Card, Flex } from 'components/layout';
import { Text } from 'components/typography';
import * as Icons from 'components/icons';
import { formatDate, YEAR_MONTH_MONTHDAY } from 'utils/time-and-date';

export const CustomerProductCard = ({ product }) => (

  <Card
    variant="primary"
  >
    <Flex
      flexDirection="column"
      gap="3px"
      sx={{
        width: '35%',
      }}
    >
      <Flex 
        sx={{ 
          textDecoration: 'underline', 
        }}
      >
        <Text>
          <Text
            fontWeight="bold"
          >
            Product:
          </Text> {product.product}
        </Text>
      </Flex>

      <Text>
        Rent: {product.totalRent} SEC Monthly
      </Text>
    </Flex>

    <Flex
      flexDirection="column"
      gap="3px"
      sx={{
        justifyContent: 'space-between',
        width: '25%',
      }}
    >
      <Text>
        Active: {formatDate(new Date(product.activeFrom), YEAR_MONTH_MONTHDAY)}
      </Text>

      <Flex>
        <Text>
          {'Contract: '}
          <Text
            fontWeight="bold"
            sx={{
              textDecoration: 'underline',
            }}
          >{product.agreementNumber}
          </Text>
        </Text>
      </Flex>
    </Flex>

    <Flex
      flexDirection="column"
      gap="3px"
      sx={{
        width: '10%',
      }}
    >
      <Icons.AddSquare
        sx={{
          fill: 'primary-disabled',
        }}
      />
    </Flex>

    <Flex
      flexDirection="column"
      gap="3px"
      sx={{
        width: '40%',
      }}
    >
      <Text>
        Address: {product.address}
      </Text>
    </Flex>
  </Card>
);
