export function mapDataForBackend(users) {
  return {
    inviteUsers: users.reduce((accumulator, currentValue) => {
      accumulator.push({
        ...currentValue,
        role: currentValue.role.value,
      });
      return accumulator;
    }, []),
  };
}

export const mapDataToClient = (data, succeeded) => data.filter(d => d.succeeded === succeeded).map(d => d.email);
