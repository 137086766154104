import React from 'react';
import * as Icons from 'components/icons';
import { useUserRole } from 'store/user';
import { Routing } from 'global/routing';
import { useLocation } from 'react-router-dom';
import { SideBarMenu } from './components/SideBarMenu';

export const SideBar = () => {
  const { role } = useUserRole();
  const { pathname } = useLocation();

  return (
    <SideBarMenu>
      <SideBarMenu.NavMenu
        icon={<Icons.Overview />}
        text="Overview"
        to={Routing.HOME_PAGE}
        isDisabled
      />

      <SideBarMenu.NavMenu
        icon={<Icons.Tasks />}
        text="Tasks"
        to="/"
        isDisabled
      />

      <SideBarMenu.NavMenu
        icon={<Icons.Magnify size={17} />}
        text="Search"
        isSelected={pathname === Routing.HOME_PAGE}
        to={Routing.HOME_PAGE}
      />

      {
          role?.toLowerCase().includes('admin') && (
          <SideBarMenu.NavMenu
            icon={<Icons.Users />}
            to={Routing.USERS_PAGE}
            isSelected={pathname.includes('users')}
            text="Users"
          />
          )
      }

      <SideBarMenu.DropdownMenu
        icon={<Icons.Building />}
        text="Building"
        isDisabled
      />

      <SideBarMenu.DropdownMenu
        icon={<Icons.Product />}
        text="Product"
        isDisabled
      />

      <SideBarMenu.DropdownMenu
        icon={<Icons.Operations />}
        text="Operations"
        isDisabled
      />

      <SideBarMenu.DropdownMenu
        icon={<Icons.Fulfillment />}
        text="Fulfillment"
        isDisabled
      />

      <SideBarMenu.DropdownMenu
        icon={<Icons.Assurance />}
        text="Assurance"
        isDisabled
      />

      <SideBarMenu.DropdownMenu
        icon={<Icons.Billing />}
        text="Billing"
        isDisabled
      />

      <SideBarMenu.DropdownMenu
        icon={<Icons.Enterprise />}
        text="Enterprise"
        isDisabled
      />

      <SideBarMenu.DropdownMenu
        icon={<Icons.Tools />}
        text="Tools"
        isDisabled
      />

      <SideBarMenu.DropdownMenu
        icon={<Icons.Helpcenter />}
        text="Helpcenter"
        isDisabled
      />

      <SideBarMenu.DropdownMenu
        icon={(
          <Icons.Settings size={18} />
      )}
        text="Settings"
        isDisabled
      />

    </SideBarMenu>
  ); 
};
