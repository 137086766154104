import React from 'react';
import { components } from 'react-select';
import { BoxArrow } from 'components/icons';
import styles from './styles.module.scss';

export const DropdownIndicator = (props) => (
  <components.DropdownIndicator
    {...props}
    className={styles.dropdownIndicator}
  >
    <BoxArrow className={styles.boxArrow} />
  </components.DropdownIndicator>
);
