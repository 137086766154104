import React from 'react';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';

export const LabeledSection = ({ children, icon, label }) => (
  <Flex
    flexDirection="column"
  >
    <Flex
      alignItems="center"
      gap="17px"
      sx={{ marginBottom: '40px' }}
    >
      {React.cloneElement(icon, { sx: { fill: 'primary' } })}

      <Text
        as="h3"
        fontSize="22px"
      >
        {label}
      </Text>
    </Flex>

    {children}
  </Flex>
);
