import React, { useState, useRef } from 'react';
import { Button } from 'components/form-elements';
import { Text } from 'components/typography';
import { BoxArrow } from 'components/icons';
import { ShouldRender } from 'components/ShouldRender';

function Accordion({ children, title }) {
  const [isActive, setIsActive] = useState(false);

  const $content = useRef(null);

  return (
    <>
      <Button
        variant="accordion"
        onClick={() => setIsActive(!isActive)}
      >
        <Text variant="accordion">{title}</Text>
        <BoxArrow paddingRight="10px" />
      </Button>
      <ShouldRender when={isActive}>
        <div
          ref={$content}
          style={{
            backgroundColor: 'bg.100',
            overflowY: 'auto',
            transition: 'max-height 0.3s ease',
          }}
        >
          {children}
        </div>
      </ShouldRender>
    </>
  );
}

export default Accordion;
