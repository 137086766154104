import React from 'react';
import { IconButton } from 'components/icons/IconButton';

export const Card = ({ size = 30, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27.063 21.425"
    >
      <g transform="translate(0 -2.5)">
        <path className="a"
              d="M27.063,8.138a2.255,2.255,0,0,0-2.255-2.255H22.834a.282.282,0,0,1-.282-.282V3.628A1.128,1.128,0,0,0,21.425,2.5H2.255A2.255,2.255,0,0,0,0,4.755V18.287a1.128,1.128,0,0,0,1.128,1.128H3.1a.282.282,0,0,1,.282.282V21.67a2.255,2.255,0,0,0,2.255,2.255H9.021A1.128,1.128,0,0,0,10.149,22.8a.846.846,0,0,1,1.691,0,1.128,1.128,0,0,0,1.128,1.128h4.51A1.128,1.128,0,0,0,18.606,22.8a.846.846,0,0,1,1.691,0,1.128,1.128,0,0,0,1.128,1.128h3.383a2.255,2.255,0,0,0,2.255-2.255ZM24.808,21.106a.564.564,0,0,1-.564.564H22.7a.564.564,0,0,1-.5-.3,3.1,3.1,0,0,0-5.5,0,.564.564,0,0,1-.5.3H14.245a.564.564,0,0,1-.5-.3,3.1,3.1,0,0,0-5.5,0,.564.564,0,0,1-.5.3H6.2a.564.564,0,0,1-.564-.564V8.7A.564.564,0,0,1,6.2,8.138H24.244a.564.564,0,0,1,.564.564Z"/>
        <path className="a"
              d="M14.75,10.846a.846.846,0,0,0,.846.846H20.67a.846.846,0,1,0,0-1.691H15.6a.846.846,0,0,0-.846.846Z"
              transform="translate(1.882 0.957)"/>
        <path className="a" d="M18.415,13H15.6a.846.846,0,0,0,0,1.691h2.819a.846.846,0,0,0,0-1.691Z"
              transform="translate(1.882 1.34)"/>
        <circle className="a" cx="2.255" cy="2.255" r="2.255" transform="translate(8.649 9.266)"/>
        <path className="a"
              d="M10.095,13.5a4.059,4.059,0,0,0-3.716,2.612.564.564,0,0,0,.531.771h6.368a.564.564,0,0,0,.531-.771A4.059,4.059,0,0,0,10.095,13.5Z"
              transform="translate(0.809 1.404)"/>
      </g>
    </svg>
  </IconButton>
);
