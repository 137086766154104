import React from 'react';
import { NavigationUser } from 'components/icons';
import { UserActions } from 'pages/Users/components/UserActions';
import { Popup } from 'components/Popup';

export const columns = [
  {
    Header: 'Username',
    accessor: 'username',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Tenant',
    accessor: 'tenantId',
  },
  {
    Header: 'Role',
    accessor: 'role',
  },
  {
    accessor: 'userId',
    disableSortBy: true,
    Cell: ({ row }) => (
      <Popup
        position="bottom right"
        trigger={(
          <div>
            <NavigationUser
              sx={{
                cursor: 'pointer',
              }}
            />
          </div>
        )}
      >
        <UserActions
          user={row.original}
        />
      </Popup>
    ),
  },
];

export const mapDataToAccessor = user => ({
  username: `${user.firstName} ${user.lastName}`,
  email: user.email,
  tenantId: user.tenantId,
  role: user.role,
  userId: user.userId,
  status: user.status,
});
