import React from 'react';
import { TextButtonWithIcon } from 'components/form-elements';
import * as Icons from 'components/icons';
import { ExportToCsv } from 'export-to-csv';

export const ExportTable = ({ rows, prepareRow }) => {
  const visibleData = React.useMemo(() => rows.map(row => {
    prepareRow(row);
    return row.cells.reduce((prev, curr) => ({ ...prev, [curr.column.Header]: curr.value }), {});
  }), [rows, prepareRow]);

  const exportTable = () => {
    const csvExporter = new ExportToCsv({
      filename: 'customers_table',
      useKeysAsHeaders: true,
      showLabels: true,
    });

    csvExporter.generateCsv(visibleData);
  };

  return (
    <TextButtonWithIcon
      icon={<Icons.Excel />}
      onClick={exportTable}
    >
      Export Table
    </TextButtonWithIcon>
  );
};
