import React from 'react';
import ReactPopup from 'reactjs-popup';

const overlayStyles = {
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  overflowY: 'auto',
  outline: 'none',
  padding: '15px',
};

const contentStyles = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
};

export const Popup = ({
  isOpen,
  isModal,
  isNested,
  lockScroll,
  position,
  trigger,
  onClose,
  closeOnOutsideClick,
  children,
}) => (
  <ReactPopup
    lockScroll={lockScroll}
    open={isOpen}
    modal={isModal}
    nested={isNested}
    position={position}
    trigger={trigger}
    overlayStyle={isModal ? overlayStyles : { outline: 'none' }}
    contentStyle={isModal && contentStyles}
    closeOnDocumentClick={closeOnOutsideClick}
    closeOnEscape={closeOnOutsideClick}
    onClose={onClose}
  >
    {children}
  </ReactPopup>
);
