import React from 'react';
import { Box } from 'components/layout';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export const SkeletonLoader = ({ count = 5, color, highlightColor }) => (
  <Box
    sx={{ width: '100%', height: '100%' }}
  >
    <SkeletonTheme
      color={color}
      highlightColor={highlightColor}
    >
      <Skeleton count={count} />
    </SkeletonTheme>
  </Box>
);

