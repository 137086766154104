import React from 'react';
import * as ThemeUI from 'theme-ui';

export const Input = ({
  value,
  id,
  name,
  type = 'search',
  variant,
  isDisabled,
  placeholder,
  onChange,
  onBlur,
  sx,
}) => (
  <ThemeUI.Input
    variant={variant}
    id={id}
    type={type}
    value={value || ''}
    disabled={isDisabled}
    placeholder={placeholder}
    name={name}
    onChange={onChange}
    onBlur={onBlur}
    sx={sx}
  />
);
