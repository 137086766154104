import React from 'react';
import { Button } from 'components/form-elements';

export const TextButtonWithIcon = ({ icon, children, ...props }) => (
  <Button
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}
    variant="text.inline"
    {...props}
  >
    {children}
    
    {React.cloneElement(icon, {
      size: 20,
      sx: { 
        fill: 'text-primary',
        marginLeft: '10px',
      }, 
    })}
  </Button>
);
