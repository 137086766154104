import { breakpoints } from 'utils/breakpoints';

export const layout = {
  page: {
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: breakpoints({ _: '1105px', xl: '1290px' }),
      height: 'fit-content',
      padding: '23px',
      px: breakpoints({ _: '23px', xl: '112px' }),
      backgroundColor: 'bg.0',
      boxShadow: 1,

      '& > * + *': {
        marginTop: '65px!important',
      },
    },
  },

  section: {
    content: {
      padding: '35px 40px',
      backgroundColor: 'bg.100',
      boxShadow: 2,
    },
  },
};
