import React from 'react';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';
import * as Icons from 'components/icons';
import { Button } from 'components/form-elements';
import { logout } from 'utils/auth';
import { useUser } from 'store/user';
import { Logo, HeaderMenu } from './components';

export const Header = () => {
  const { user } = useUser();

  const handleLogout = async () => {
    await logout();
  };

  return (
    <Flex
      as="header"
      sx={{
        height: '80px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'bg.0',
        boxShadow: 2,
        px: '45px',
      }}
    >
      <Logo />

      <HeaderMenu>
        <HeaderMenu.TextMenu
          isDisabled
          icon={<Icons.NotificationBell />}
        >
          Notifications
        </HeaderMenu.TextMenu>

        <HeaderMenu.MenuWithIcon icon={<Icons.User />}>
          <Flex
            gap="5px"
            alignItems="center"
          >
            <Text variant="header.menu">
              {user.firstName} {user.lastName}
            </Text>
          </Flex>
        </HeaderMenu.MenuWithIcon>

        <HeaderMenu.TextMenu
          isDisabled
          icon={<Icons.Settings />}
        >
          Settings
        </HeaderMenu.TextMenu>
        <Button
          sx={{
            backgroundColor: 'bg.0',
            color: 'bg.800',
            textTransform: 'uppercase',
          }}
          onClick={() => handleLogout()}
        >
          Logout
        </Button>
      </HeaderMenu>
    </Flex>
  );
};
