import { getAuthToken } from 'utils/auth/token';
import jwtDecode from 'jwt-decode';

// Will be changed when we have other tenants in the portal
const TENANT_ID = 'vxb';

export async function getUserRole() {
  const idToken = await getAuthToken();
  const isSuperAdmin = jwtDecode(idToken).isSuperAdmin === '1';
  const isAdmin = JSON.parse(jwtDecode(idToken).tenantsRole)[TENANT_ID] === 'admin';

  if (isSuperAdmin) {
    return 'superAdmin';
  } if (isAdmin) {
    return 'admin';
  } return 'user';
}
