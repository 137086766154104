import React from 'react';
import { IconButton } from 'components/icons/IconButton';

export const Settings = ({ size = 25, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
    >
      <defs>
      </defs>
      <path d="M20.781,8.125,22.5,4.844,20.313,2.656,17.031,4.375a5.6,5.6,0,0,0-1.719-.625L14.063,0H10.938L9.688,3.594a6.53,6.53,0,0,0-1.562.625L4.844,2.5,2.5,4.844,4.219,8.125a6.53,6.53,0,0,0-.625,1.563L0,10.938v3.125l3.594,1.25c.156.625.469,1.094.625,1.719L2.5,20.313,4.688,22.5l3.281-1.719a5.6,5.6,0,0,0,1.719.625L10.938,25h3.125l1.25-3.594c.625-.156,1.094-.469,1.719-.625L20.313,22.5,22.5,20.313l-1.719-3.281a5.6,5.6,0,0,0,.625-1.719L25,14.063V10.938l-3.594-1.25A6.53,6.53,0,0,0,20.781,8.125ZM12.5,17.188A4.6,4.6,0,0,1,7.813,12.5,4.6,4.6,0,0,1,12.5,7.813,4.6,4.6,0,0,1,17.188,12.5,4.6,4.6,0,0,1,12.5,17.188Z" />
    </svg>
  </IconButton>
);
