import React from 'react';
import { IconButton } from 'components/icons/IconButton';

export const Calendar = ({ size = 18, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.268 16.863"
    >
      <g id="Icon_ionic-ios-calendar" data-name="Icon ionic-ios-calendar" transform="translate(-3.375 -4.5)">
        <path id="Path_8224" data-name="Path 8224" d="M19.887,6.75H18.13V7.8a.352.352,0,0,1-.351.351h-.7a.352.352,0,0,1-.351-.351V6.75H8.293V7.8a.352.352,0,0,1-.351.351h-.7A.352.352,0,0,1,6.888,7.8V6.75H5.132A1.762,1.762,0,0,0,3.375,8.507V20.451a1.762,1.762,0,0,0,1.757,1.757H19.887a1.762,1.762,0,0,0,1.757-1.757V8.507A1.762,1.762,0,0,0,19.887,6.75Zm.351,13.174a.881.881,0,0,1-.878.878H5.659a.881.881,0,0,1-.878-.878v-7.9a.352.352,0,0,1,.351-.351H19.887a.352.352,0,0,1,.351.351Z" transform="translate(0 -0.845)" fill="#979797"/>
        <path id="Path_8225" data-name="Path 8225" d="M10.405,4.851a.352.352,0,0,0-.351-.351h-.7A.352.352,0,0,0,9,4.851V5.905h1.405Z" transform="translate(-2.112)" fill="#979797"/>
        <path id="Path_8226" data-name="Path 8226" d="M26.155,4.851A.352.352,0,0,0,25.8,4.5h-.7a.352.352,0,0,0-.351.351V5.905h1.405Z" transform="translate(-8.025)" fill="#979797"/>
      </g>
    </svg>
  </IconButton>
);
