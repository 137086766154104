import React, { useEffect, useCallback } from 'react';
import { Card, Flex } from 'components/layout';
import { ShouldRender } from 'components/ShouldRender';
import { Form, Button, EditableTextField } from 'components/form-elements';
import { LabeledText } from 'components/typography';
import { useUpdateCustomer } from 'services/update-customer';
import { useForm } from 'utils/form';

export const CustomerInfoCard = ({ 
  selectedCustomer, 
  setSelectedCustomer, 
  shouldEditCustomerInfo,
  setShouldEditCustomerInfo,
}) => {
  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: selectedCustomer,
  });

  const { updateCustomer, isLoading } = useUpdateCustomer({
    onSuccess: (response, updatedCustomer) => {
      setShouldEditCustomerInfo(false);
      setSelectedCustomer({ ...selectedCustomer, ...updatedCustomer });
    },
  });

  const onSubmit = (updatedCustomer) => {
    updateCustomer({ ...selectedCustomer, ...updatedCustomer });
  };

  const onCancelUpdate = useCallback(() => {
    setShouldEditCustomerInfo(false);
    reset(selectedCustomer);
  }, [selectedCustomer, setShouldEditCustomerInfo, reset]);

  useEffect(() => {
    onCancelUpdate();
  }, [onCancelUpdate]);

  return (
    <Card variant="primary">
      <Form
        sx={{
          flexGrow: 1,
          flexWrap: 'wrap',
          color: 'bg.600',
          backgroundColor: 'bg.0',
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <LabeledText title="First Name">
          <EditableTextField
            forwardRef={register({ required: true })}
            name="firstName"
            hasError={errors.firstName}
            isDisabled={!shouldEditCustomerInfo}
          />
        </LabeledText>

        <LabeledText title="Last Name">
          <EditableTextField
            forwardRef={register({ required: true })}
            name="lastName"
            hasError={errors.lastName}
            isDisabled={!shouldEditCustomerInfo}
          />
        </LabeledText>

        <LabeledText title="Building Number">
          {selectedCustomer?.object[0]?.building}
        </LabeledText>

        <LabeledText title="Apartment Number">
          {selectedCustomer?.object[0]?.apartmentno}
        </LabeledText>

        <LabeledText title="Address">
          <EditableTextField
            forwardRef={register}
            name="address"
            isDisabled={!shouldEditCustomerInfo}
          />
        </LabeledText>

        <LabeledText title="Mobile">
          <EditableTextField
            forwardRef={register}
            name="mobile"
            isDisabled={!shouldEditCustomerInfo}
          />
        </LabeledText>

        <LabeledText
          title="Email"
          sx={{
            width: '50%',
          }}
        >
          <EditableTextField
            forwardRef={register}
            name="email"
            isDisabled={!shouldEditCustomerInfo}
          />
        </LabeledText>

        <ShouldRender when={shouldEditCustomerInfo}>
          <Flex
            gap="13px"
            justifyContent="flex-end"
            sx={{
              width: '100%',
              paddingRight: '20px',
            }}
          >
            <Button
              type="submit"
              variant="primary"
              isLoading={isLoading}
            >
              Update
            </Button>

            <Button
              variant="secondary"
              onClick={onCancelUpdate}
            >
              Cancel
            </Button>
          </Flex>
        </ShouldRender>
      </Form>
    </Card>
  );
};
