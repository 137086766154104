import React from 'react';
import { Flex } from 'components/layout';
import * as Icons from 'components/icons';
import { Text } from 'components/typography';

const padding = '10px 15px';
export const CustomerCardHeader = ({ category }) => (

  <Flex
    sx={{
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'bg.300',
      boxShadow: 2,
      padding,
    }}
  >
    <Text
      variant="card.label"
      sx={{
        color: 'bg.600',
      }}
    >
      {category}
    </Text>

    <Flex
      gap="5px"
      sx={{
        fill: 'primary-disabled',
        mr: 20,
      }}
    >
      <Icons.Compass />

      <Text
        isFaded
      >
        Find on map
      </Text>
    </Flex>
  </Flex>
   
);
