import React from 'react';
import { isEqual } from 'lodash-es';
import { Form, LabeledInput, LabeledSelect, LabeledDateRangePicker, ButtonWithIcon } from 'components/form-elements';
import { Container, Flex } from 'components/layout';
import { Text } from 'components/typography';
import { useSearchCustomers } from 'services/search-customers';
import * as Icons from 'components/icons';
import { BuildingId, SearchColumn } from './components';

const initialSearchFilters = {
  freeTextSearch: '',
  address: '',
  phoneNumber: '',
  customerType: null,
  area: null,
  buildingId: null,
  organizationNumber: '',
  portal: null,
  date: { selectedDateFrom: null, selectedDateTo: null },
  process: null,
  productGroup: null,
  provider: null,
  status: null,
};

export const Search = ({ setSearchedFilters, setSelectedCustomer }) => {
  const [searchFilters, setSearchFilters] = React.useState(initialSearchFilters);

  const { searchCustomers, isLoading } = useSearchCustomers(searchFilters);

  return (
    <Container variant="section.content">
      <Form
        sx={{
          flexDirection: 'column',
          gap: '40px',
        }}
        onSubmit={() => {
          searchCustomers();
          setSearchedFilters(searchFilters);
          setSelectedCustomer(null);
        }}
      >
        <Flex gap="40px">
          <SearchColumn>
            <LabeledInput
              id="free-text-search"
              name="freeTextSearch"
              label="Free text search"
              value={searchFilters.freeTextSearch}
              onChange={e => setSearchFilters({ ...searchFilters, freeTextSearch: e.target.value })}
            />

            <LabeledInput
              id="address"
              name="address"
              label="Address"
              value={searchFilters.address}
              onChange={e => setSearchFilters({ ...searchFilters, address: e.target.value })}
            />

            <LabeledInput
              id="phone-number"
              name="phoneNumber"
              label="Phone number"
              value={searchFilters.phoneNumber}
              onChange={e => setSearchFilters({ ...searchFilters, phoneNumber: e.target.value })}
            />

            <LabeledSelect
              id="customer-type"
              name="customerType"
              label="Customer type"
              value={searchFilters.customerType
                ? { label: searchFilters.customerType.label, value: searchFilters.customerType.value } : null}
              options={[
                { value: 'residential', label: 'Residential' },
                { value: 'organization', label: 'Organization' },
              ]}
              onChange={option => setSearchFilters({ ...searchFilters, customerType: option || null })}
            />
          </SearchColumn>

          <SearchColumn>
            <LabeledSelect
              id="area"
              name="area"
              label="Area"
              value={searchFilters.area
                ? { label: searchFilters.area.label, value: searchFilters.area.value } : null}
              options={[
                { value: 'utopia', label: 'Utopia' },
                { value: 'xanandu', label: 'Xanandu' },
              ]}
              onChange={option => setSearchFilters({ ...searchFilters, area: option || null })}
            />

            <Flex gap="17px">
              <BuildingId
                id="building-id"
                name="buildingId"
                label="Building ID"
                value={searchFilters.buildingId
                  ? { label: searchFilters.buildingId.label, value: searchFilters.buildingId.value } : null}
                onChange={option => setSearchFilters({ ...searchFilters, buildingId: option || null })}
              />

              <LabeledSelect
                isDisabled
                id="portal"
                name="portal"
                label="Portal"
              />
            </Flex>

            <LabeledInput
              id="organization-number"
              name="organizationNumber"
              label="Organization number"
              value={searchFilters.organizationNumber}
              onChange={e => setSearchFilters({ ...searchFilters, organizationNumber: e.target.value })}
            />

            <LabeledDateRangePicker
              id="date"
              label="Date range"
              selectedDate={searchFilters.date}
              onChange={selectedDate => setSearchFilters({ ...searchFilters, date: selectedDate })}
            />
          </SearchColumn>

          <SearchColumn>
            <LabeledSelect
              id="process"
              name="process"
              label="Process"
              value={searchFilters.process
                ? { label: searchFilters.process.label, value: searchFilters.process.value } : null}
              options={[
                { value: 'maintenance_order_customer', label: 'maintenance_order_customer' },
              ]}
              onChange={option => setSearchFilters({ ...searchFilters, process: option || null })}
            />

            <LabeledSelect
              id="product-group"
              name="productGroup"
              label="Product group"
              value={searchFilters.productGroup
                ? { label: searchFilters.productGroup.label, value: searchFilters.productGroup.value } : null}
              options={[
                { value: 'maintenance_customer', label: 'Maintenance_Customer' },
              ]}
              onChange={option => setSearchFilters({ ...searchFilters, productGroup: option || null })}
            />

            <LabeledSelect
              id="provider"
              name="provider"
              label="Provider"
              value={searchFilters.provider
                ? { label: searchFilters.provider.label, value: searchFilters.provider.value } : null}
              options={[
                { value: 'VXB', label: 'VXB' },
              ]}
              onChange={option => setSearchFilters({ ...searchFilters, provider: option || null })}
            />

            <LabeledSelect
              id="status"
              name="status"
              label="Status"
              value={searchFilters.status
                ? { label: searchFilters.status.label, value: searchFilters.status.value } : null}
              options={[
                { value: 'Ordered', label: 'Ordered' },
                { value: 'Ongoing', label: 'Ongoing' },
                { value: 'Delivered', label: 'Delivered' },
                { value: 'Rejected', label: 'Rejected' },
              ]}
              onChange={option => setSearchFilters({ ...searchFilters, status: option || null })}
            />
          </SearchColumn>
        </Flex>

        <Flex
          justifyContent="space-between"
          alignItems="center"
        >
          <Text
            isFaded
            fontSize="14px"
            fontWeight="bold"
          >
            Here you can search in more detail and export your search results into Excel
          </Text>

          <Flex gap="13px">
            <ButtonWithIcon
              variant="secondary"
              icon={<Icons.Reload />}
              onClick={() => setSearchFilters(initialSearchFilters)}
            >
              Reset
            </ButtonWithIcon>

            <ButtonWithIcon
              type="submit"
              icon={<Icons.Search />}
              isLoading={isLoading}
              isDisabled={isEqual(searchFilters, initialSearchFilters)}
            >
              Search
            </ButtonWithIcon>
          </Flex>
        </Flex>
      </Form>
    </Container>
  );
};
