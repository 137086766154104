import React from 'react';
import { Button } from 'components/form-elements/buttons/Button';

export const TabButton = ({ children, isDisabled }) => (
  <Button
    isDisabled={isDisabled}
    sx={{
      width: '125px',
      height: '45px',
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'bg.800',
      backgroundColor: 'bg.300',
      boxShadow: 3,

      '&:disabled': {
        color: 'primary-disabled',
      },
    }}
  >
    {children}
  </Button>
);
