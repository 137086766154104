import React, { useMemo } from 'react';
import { LabeledSelect } from 'components/form-elements';
import { useBuildingIds } from 'services/building-ids';

export const BuildingId = ({ 
  id,
  name,
  label,
  value,
  onChange,
}) => {
  const { buildingIds, isLoading } = useBuildingIds();

  const buildingIdsAsOptions = useMemo(() => (
    buildingIds?.map(buildingId => ({ value: buildingId, label: buildingId }))
  ), [buildingIds]);

  return (
    <LabeledSelect
      id={id}
      name={name}
      label={label}
      isLoading={isLoading}
      value={value}
      options={buildingIdsAsOptions}
      onChange={onChange}
    />
  );
};
