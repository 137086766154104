import React from 'react';
import { Flex } from 'components/layout';
import { useTable, useSortBy } from 'react-table';
import { ShouldRender } from 'components/ShouldRender';
import { Text } from 'components/typography';
import { DisplayTable, ExportTable, ToggleColumns } from './components';

export const Table = ({
  data,
  columns,
  showToggleColumns = true,
  showTotalResults = true,
  showExportTable = true,
  clickedRow,
  onRowClick,
  typeOrder,
}) => {
  const tableData = React.useMemo(() => data, [data]);
  const tableColumns = React.useMemo(() => columns, [columns]);

  const tableContext = useTable({ columns: tableColumns, data: tableData }, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    allColumns,
    prepareRow,
    footerGroups,
  } = tableContext;
  
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Flex gap="60px">
        <ShouldRender when={showToggleColumns}>
          <ToggleColumns allColumns={allColumns} />
        </ShouldRender>

        <ShouldRender when={showExportTable && rows.length}>
          <ExportTable
            rows={rows}
            prepareRow={prepareRow}
          />
        </ShouldRender>

        <ShouldRender when={showTotalResults}>
          <Text
            fontWeight="bold"
            fontSize={14}
            isFaded
            sx={{
              marginLeft: 'auto',
            }}
          >
            {rows.length} Results
          </Text>
        </ShouldRender>
      </Flex>

      <DisplayTable
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        footerGroups={footerGroups}
        clickedRow={clickedRow}
        onRowClick={onRowClick}
        typeOrder={typeOrder}
      />
    </Flex>
  );
};
