import { Auth } from 'aws-amplify';

export async function getAuthToken() {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (e) {
    return null;
  }
}
