import React from 'react';
import { IconButton } from 'components/icons/IconButton';

export const Sigma = ({ size = 17, sx }) => (
  <IconButton
    as="div"
    sx={{
      stroke: 'white',
      ...sx,
    }}
  >
    <svg
      style={{ width: size, }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.144 21.16"
    >
      <path
            d="M15.7,20.16H1.089A.756.756,0,0,1,.5,18.928L7.676,10.08.5,1.233A.757.757,0,0,1,1.089,0H15.7a.756.756,0,0,1,.756.756v2.52a.756.756,0,0,1-1.512,0V1.512H2.675L9.236,9.6a.757.757,0,0,1,0,.953L2.675,18.649H14.949V16.884a.756.756,0,0,1,1.512,0V19.4a.756.756,0,0,1-.756.756Zm0,0"
            transform="translate(0.183 0.5)"/>
    </svg>
  </IconButton>
);
