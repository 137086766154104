import { useMutation } from 'react-query';
import { useModal } from 'store/modals';
import { inviteUsersAPI } from './api';
import { mapDataToClient } from './mapData';

export function useInviteUsers({ onSuccess }) {
  const { openModal } = useModal();

  const { mutate, isLoading, isError } = useMutation(
    (users) => inviteUsersAPI(users),
    {
      onError: (error, users) => openModal({
        modalType: 'PopupModal',
        modalProps: {
          title: 'Unable to invite users',
          description: 'An error occurred while inviting the users.',
          onRetry: () => mutate(users),
        },
      }),
      onSuccess: (data) => openModal({
        modalType: 'TableModal',
        modalProps: {
          firstColumnHeader: 'Successfully invited: ',
          firstColumnData: mapDataToClient(data, true),
          secondColumnHeader: 'Failed to invite: ',
          secondColumnData: mapDataToClient(data, false),
          onClose: () => onSuccess(),
        },
      }),
    },
  );

  return {
    inviteUsers: mutate,
    isLoading,
    isError,
  };
}
