import React from 'react';
import { Flex } from 'components/layout';

export const MenuWithIcon = ({ children, icon, isDisabled }) => (
  <Flex
    gap="15px"
    alignItems="center"
    sx={{
      cursor: isDisabled && 'not-allowed',
    }}
  >
    {children}

    {React.cloneElement(icon, { sx: { fill: isDisabled ? 'primary-disabled' : 'primary' } })}
  </Flex>
);
