import React from 'react';
import { IconButton } from '../IconButton';

export const NotificationBell = ({ size = 27, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 29.42"
    >
      <g transform="translate(-0.361 2)">
        <path d="M7.863,18.347H13.1a2.621,2.621,0,1,1-5.241,0ZM1.31,17.037a1.31,1.31,0,0,1,0-2.621h.656a5.705,5.705,0,0,0,1.965-3.932V6.553A6.49,6.49,0,0,1,10.484,0a6.489,6.489,0,0,1,6.551,6.553v3.931A5.7,5.7,0,0,0,19,14.416h.656a1.31,1.31,0,0,1,0,2.621Z" transform="translate(0.361 6.452)"/>
        <g transform="translate(14.877)">
          <circle cx="5.242" cy="5.242" r="5.242"/>
          <circle fill="transparent" cx="5.242" cy="5.242" r="6.242"/>
        </g>
      </g>
    </svg>
  </IconButton>
);

