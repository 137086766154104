import React from 'react';
import { ModalProvider } from 'store/modals';
import { UserProvider } from 'store/user';

export const StoreProvider = ({ children }) => (
  <UserProvider>
    <ModalProvider>
      {children}
    </ModalProvider>
  </UserProvider>
);
