import { authorize, getRequest } from 'utils/http';
import { Environment } from 'global/environment';
import { mapDataForClient } from './mapData';

export async function getUsersAPI() {
  try {
    const users = await getRequest(`${Environment.BACKEND_API}/admin/users`, await authorize());
    return Promise.resolve(users.users.map((user) => mapDataForClient(user)));
  } catch (error) {
    return Promise.reject(error);
  }
}
