import { useQuery } from 'react-query';
import { useModal } from 'store/modals';
import { searchCustomersAPI } from './api';

const SEARCH_CUSTOMERS_KEY = 'searchCustomers';

export function useSearchCustomers(searchFilters) {
  const { openModal } = useModal();

  const { data, isLoading, isError, refetch } = useQuery(
    [SEARCH_CUSTOMERS_KEY, searchFilters],
    () => searchCustomersAPI(searchFilters),
    {
      enabled: false,
      onError: () => openModal({
        modalType: 'PopupModal',
        modalProps: {
          title: 'Unable to find your results',
          description: 'An error occurred while searching for your results.',
          onRetry: refetch,
        },
      }),
    },
  );

  const searchCustomers = () => {
    if (!data) {
      refetch();
    }
  };

  return {
    customers: data,
    isLoading,
    isError,
    searchCustomers,
  };
}
