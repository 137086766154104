import React from 'react';
import { IconButton } from '../IconButton';

export const Add = ({ size=19, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 19"
    >
      <g id="Icon_feather-plus-circle" transform="translate(-10.5 -10.5)">
        <path id="Path_9518" d="M18,12V29.158" transform="translate(2.579 0)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
        <path id="Path_9519" d="M12,18H29.158" transform="translate(0 2.579)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
      </g>
    </svg>
  </IconButton>
);
