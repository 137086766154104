import React, { useState } from 'react';
import { Text } from 'components/typography';
import * as Icons from 'components/icons';
import { Box, Flex } from 'components/layout';
import { Link } from 'components/links';
import { Button } from 'components/form-elements';
import { MenuContainer } from '../MenuContainer';

export const DropdownMenu = ({ isDisabled, icon, text, items }) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(prevState => (isDisabled ? false : !prevState));
  };

  return (
    <Flex sx={{ flexDirection: 'column' }}>

      <Button
        onClick={toggleDropdown}
        sx={{ '&:focus': { outline: '2px solid black' } }}
      >
        <MenuContainer
          isDisabled={isDisabled}
          icon={icon}
        >
          <Box
          // Blue vertical line visible only on click
            sx={{
              opacity: isOpen ? '1' : '0',
              backgroundColor: 'primary',
              height: '100%',
              width: '5px',
              position: 'absolute',
              top: 0,
              left: 0,
              transition: 'opacity 0.3s',
            }}
          />

          <Flex
            sx={{ flexGrow: 1, alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Text
              isFaded={isDisabled}
              sx={isOpen && { textTransform: 'uppercase' }}
            >
              {text}
            </Text>

            <Icons.ChevronRight
              sx={isOpen
                ? { transform: 'rotate(90deg)', transition: 'all 0.3s' }
                : { transform: 'rotate(0deg)', transition: 'all 0.3s' }}
            />
          </Flex>
        </MenuContainer>
      </Button>

      <Box
        // The dropdown menu
        sx={{
          visibility: isOpen ? 'visible' : 'hidden',
          maxHeight: isOpen ? '500px' : '0',
          opacity: isOpen ? '1' : '0',
          px: isOpen ? '20px' : '0',
          py: isOpen ? '10px' : '0',
          ml: isOpen ? '5px' : '0',
          mb: isOpen ? '3px' : '0',
          backgroundColor: 'bg.200',
          transition: isOpen
            ? 'opacity 0.5s ease-out, max-height 0.4s ease-out'
            : 'opacity 0.5s ease-out, max-height 0.1s ease-out',
        }}
      >
        {items && items.map((item, index) => (
          <Box
            sx={{ py: '6px' }}
            key={index}
          >
            <Link
              disabled={item.disabled}
              to={item.to}
              onClick={toggleDropdown}
            >
              {item.name}
            </Link>
          </Box>
        ))}

      </Box>
    </Flex>
  );
};
