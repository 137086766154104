import React from 'react';
import { Flex } from 'components/layout';
import { Button } from 'components/form-elements';

export const UsersTabMenu = ({ activeTab, setActiveTab }) => {

  const tabs = [
    {
      title: 'Active',
      type: 'active',
    },
    {
      title: 'Inactive',
      type: 'inactive',
    },
  ];
  
  return (
    <Flex
      justifyContent="left"
      sx={{
        marginBottom: '12px',
        width: '100%',
        backgroundColor: 'bg.100',
      }}
    >
      {tabs.map((tab, i) => (
        <Button
          key={i}
          onClick={() => setActiveTab(tab.type)}
          sx={{
            textTransform: 'uppercase',
            width: '230px',
            height: '45px',
            fontWeight: 'bold',
            fontSize: '20px',
            backgroundColor: 'transparent',
            color: tab.type === activeTab ? 'primary' : 'bg.800',
            borderBottom: tab.type === activeTab ? '2px solid' : 'none',
          }}
        >
          {tab.title}
        </Button>
      ))}
    </Flex>
  ); 
};
