import React from 'react';
import * as Icons from 'components/icons';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';
import { formatCurrency } from 'utils/format';

// any change in accessor requires change in mapDataToAccessor function
export const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Identifier',
    accessor: 'identifier',
  },
  {
    Header: 'Order Value',
    accessor: 'orderValue',
    Footer: ({ rows }) => {
      const totalOrderValue = React.useMemo(
        () => formatCurrency(rows.reduce((prev, curr) => prev + curr.values.orderValue, 0)),
        [rows],
      );

      return (
        <Flex
          sx={{
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <Icons.Sigma sx={{ fill: 'white' }} />

          <Text
            sx={{
              color: 'white',
            }}
          >
            {totalOrderValue}
          </Text>
        </Flex>
      );
    },
    Cell: ({ value }) => formatCurrency(value),
  },
  {
    Header: 'Area',
    accessor: 'area',
  },
];

export const mapDataToAccessor = customer => ({
  name: customer.name,
  id: customer.customerNumber,
  identifier: `${customer.identifier}\t`,
  orderValue: customer.orderValue,
  area: customer.area,
});
