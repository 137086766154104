import React from 'react';
import { IconButton } from '../IconButton';

export const Discard = ({ size = 20, sx, as, onClick }) => (
  <IconButton
    as={as}
    sx={sx}
    onClick={onClick}
  >
    <svg
      style={{ width: size }}
      viewBox="0 0 34 34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="8.92041"
        y="8.05014"
        width="24"
        height="3"
        transform="rotate(45 10 8.05014)"
      />

      <rect
        x="25.891"
        y="8.92043"
        width="24"
        height="3"
        transform="rotate(135 25.891 8.92043)"
      />
    </svg>
  </IconButton>
);
