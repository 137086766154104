import React from 'react';
import { IconButton } from '../IconButton';

export const NavigationUser = ({ size= 40, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
    >
      <defs>
        <filter id="a" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse">
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="b"/>
          <feFlood floodOpacity="0.161"/>
          <feComposite operator="in" in2="b"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g transform="translate(9 5.631)">
        <g filter="url(#a)" transform="matrix(1, 0, 0, 1, -9, -5.63)">
          <rect fill="#fff" width="28" height="28" rx="7" transform="translate(9 6)"/>
        </g>
        <g transform="translate(15.5 7.5) rotate(90)">
          <rect fill="#9e9eaf" width="3" height="3"/>
          <rect fill="#9e9eaf" width="3" height="3" transform="translate(5)"/>
          <rect fill="#9e9eaf" width="3" height="3" transform="translate(10)"/>
        </g>
      </g>
    </svg>
  </IconButton>
);




