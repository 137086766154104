import React from 'react';
import { IconButton } from '../IconButton';

export const Building = ({ size = 19.084, sx, fill }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.084 19.084"
    >

    {/* Because of the way this SVG is colored, the STROKE value below must be changed manually */}
    <defs>
      <style>
        {`.BuildingA{fill:none;stroke:#bebebe;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}`}
      </style>
    </defs>

    <line className="BuildingA" y2="15.141" transform="translate(15.891 3.193)"/>
    <line className="BuildingA" x2="12.699" transform="translate(3.193 3.193)"/>
    <line className="BuildingA" y1="15.141" transform="translate(3.193 3.193)"/>
    <line className="BuildingA" x1="12.699" transform="translate(3.193 18.334)"/>
    <path className="BuildingA" d="M16.449,3.193H3.75L4.9.75h10.39Z" transform="translate(-0.557)"/>
    <line className="BuildingA" x2="17.584" transform="translate(0.75 18.334)"/>
    <path className="BuildingA" d="M13.414,20.582a1.832,1.832,0,0,0-3.664,0v1.832h3.664Z" transform="translate(-2.04 -4.08)"/>
    <path className="BuildingA" d="M7.5,6.375a.305.305,0,0,1,.305.305" transform="translate(-1.479 -1.232)"/>
    <path className="BuildingA" d="M7.125,6.68a.305.305,0,0,1,.305-.305" transform="translate(-1.396 -1.232)"/>
    <path className="BuildingA" d="M7.43,7.055a.305.305,0,0,1-.305-.305" transform="translate(-1.396 -1.314)"/>
    <path className="BuildingA" d="M7.805,6.75a.305.305,0,0,1-.305.305" transform="translate(-1.479 -1.314)"/>
    <path className="BuildingA" d="M7.5,10.875a.305.305,0,0,1,.305.305" transform="translate(-1.479 -2.218)"/>
    <path className="BuildingA" d="M7.125,11.18a.305.305,0,0,1,.305-.305" transform="translate(-1.396 -2.218)"/>
    <path className="BuildingA" d="M7.43,11.555a.305.305,0,0,1-.305-.305" transform="translate(-1.396 -2.3)"/>
    <path className="BuildingA" d="M7.805,11.25a.305.305,0,0,1-.305.305" transform="translate(-1.479 -2.3)"/>
    <path className="BuildingA" d="M7.5,15.375a.305.305,0,0,1,.305.305" transform="translate(-1.479 -3.204)"/>
    <path className="BuildingA" d="M7.125,15.68a.305.305,0,0,1,.305-.305" transform="translate(-1.396 -3.204)"/>
    <path className="BuildingA" d="M7.43,16.055a.305.305,0,0,1-.305-.305" transform="translate(-1.396 -3.286)"/>
    <path className="BuildingA" d="M7.805,15.75a.305.305,0,0,1-.305.305" transform="translate(-1.479 -3.286)"/>
    <path className="BuildingA" d="M12,6.375a.305.305,0,0,1,.305.305" transform="translate(-2.464 -1.232)"/>
    <path className="BuildingA" d="M11.625,6.68a.305.305,0,0,1,.305-.305" transform="translate(-2.382 -1.232)"/>
    <path className="BuildingA" d="M11.93,7.055a.305.305,0,0,1-.305-.305" transform="translate(-2.382 -1.314)"/>
    <path className="BuildingA" d="M12.305,6.75A.305.305,0,0,1,12,7.055" transform="translate(-2.464 -1.314)"/>
    <path className="BuildingA" d="M12,10.875a.305.305,0,0,1,.305.305" transform="translate(-2.464 -2.218)"/>
    <path className="BuildingA" d="M11.625,11.18a.305.305,0,0,1,.305-.305" transform="translate(-2.382 -2.218)"/>
    <path className="BuildingA" d="M11.93,11.555a.305.305,0,0,1-.305-.305" transform="translate(-2.382 -2.3)"/>
    <path className="BuildingA" d="M12.305,11.25a.305.305,0,0,1-.305.305" transform="translate(-2.464 -2.3)"/>
    <path className="BuildingA" d="M12,15.375a.305.305,0,0,1,.305.305" transform="translate(-2.464 -3.204)"/>
    <path className="BuildingA" d="M11.625,15.68a.305.305,0,0,1,.305-.305" transform="translate(-2.382 -3.204)"/>
    <path className="BuildingA" d="M11.93,16.055a.305.305,0,0,1-.305-.305" transform="translate(-2.382 -3.286)"/>
    <path className="BuildingA" d="M12.305,15.75a.305.305,0,0,1-.305.305" transform="translate(-2.464 -3.286)"/>
    <path className="BuildingA" d="M16.5,6.375a.305.305,0,0,1,.305.305" transform="translate(-3.45 -1.232)"/>
    <path className="BuildingA" d="M16.125,6.68a.305.305,0,0,1,.305-.305" transform="translate(-3.368 -1.232)"/>
    <path className="BuildingA" d="M16.43,7.055a.305.305,0,0,1-.305-.305" transform="translate(-3.368 -1.314)"/>
    <path className="BuildingA" d="M16.805,6.75a.305.305,0,0,1-.305.305" transform="translate(-3.45 -1.314)"/>
    <path className="BuildingA" d="M16.5,10.875a.305.305,0,0,1,.305.305" transform="translate(-3.45 -2.218)"/>
    <path className="BuildingA" d="M16.125,11.18a.305.305,0,0,1,.305-.305" transform="translate(-3.368 -2.218)"/>
    <path className="BuildingA" d="M16.43,11.555a.305.305,0,0,1-.305-.305" transform="translate(-3.368 -2.3)"/>
    <path className="BuildingA" d="M16.805,11.25a.305.305,0,0,1-.305.305" transform="translate(-3.45 -2.3)"/>
    <path className="BuildingA" d="M16.5,15.375a.305.305,0,0,1,.305.305" transform="translate(-3.45 -3.204)"/>
    <path className="BuildingA" d="M16.125,15.68a.305.305,0,0,1,.305-.305" transform="translate(-3.368 -3.204)"/>
    <path className="BuildingA" d="M16.43,16.055a.305.305,0,0,1-.305-.305" transform="translate(-3.368 -3.286)"/>
    <path className="BuildingA" d="M16.805,15.75a.305.305,0,0,1-.305.305" transform="translate(-3.45 -3.286)"/>
    </svg>
  </IconButton>
);
