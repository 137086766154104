import React, { useState } from 'react';
import { Container, Flex, LabeledSection } from 'components/layout';
import {
  LabeledInput,
  LabeledSelect,
  ButtonWithIcon,
  Form,
  Button,
} from 'components/form-elements';
import * as Icons from 'components/icons';
import { ShouldRender } from 'components/ShouldRender';
import { Text } from 'components/typography';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRedirect } from 'utils/redirect';
import { Routing } from 'global/routing';
import { useInviteUsers } from 'services/users';
import { fieldsSchema } from './validationSchema';

const initialFields = [
  {
    email: '',
    firstName: '',
    lastName: '',
    role: { value: '', label: '' },
  },
];

const roleOptions = [
  { value: 'user', label: 'user' },
  { value: 'admin', label: 'admin' },
];

export const InviteUsers = () => {
  const [tenant, setTenant] = useState('');
  const { onRedirect } = useRedirect(Routing.USERS_PAGE);

  const { control, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(fieldsSchema),
    defaultValues: {
      users: initialFields,
    },
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  const { inviteUsers, isLoading } = useInviteUsers({
    onSuccess: () => {
      reset();
      onRedirect();
    },
  });

  const onSubmit = (data) => {
    inviteUsers(data.users);
  };

  return (
    <Flex>
      <Container variant="page.content">
        <Flex
          sx={{
            flexDirection: 'column',
            width: '248px',
          }}
        >
          <LabeledSection
            label="Invite Users"
            icon={<Icons.PlusIcon size={24} />}
          />

          <LabeledSelect
            id="tenant"
            name="tenant"
            label="Select tenant"
            value={{ value: tenant, label: tenant }}
            isClearable={false}
            options={[{ value: 'vxb', label: 'vxb' }]}
            onChange={(option) => {
              setTenant(option.value);
            }}
          />
        </Flex>

        <ShouldRender when={tenant !== ''}>
          <Form
            sx={{ display: 'block!important' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            {fields.map((field, index) => {
              const fieldName = `users[${index}]`;
              return (
                <Flex
                  key={field.id}
                  gap={10}
                  sx={{
                    marginTop: '20px!important',
                    alignItems: 'flex-end',
                  }}
                >
                  <Controller
                    name={`${fieldName}.email`}
                    control={control}
                    defaultValue={field.email}
                    render={(props) => (
                      <LabeledInput
                        label="Email"
                        onChange={(e) => props.onChange(e.target.value.toLowerCase())}
                        value={props.value}
                        hasError={errors.users && errors.users[index]?.email}
                        sx={{ textTransform: 'lowercase' }}
                      />
                    )}
                  />
                  <Controller
                    name={`${fieldName}.firstName`}
                    control={control}
                    defaultValue={field.firstName}
                    render={(props) => (
                      <LabeledInput
                        label="First Name"
                        onChange={props.onChange}
                        value={props.value}
                      />
                    )}
                  />
                  <Controller
                    name={`${fieldName}.lastName`}
                    control={control}
                    defaultValue={field.lastName}
                    render={(props) => (
                      <LabeledInput
                        label="Last Name"
                        onChange={props.onChange}
                        value={props.value}
                      />
                    )}
                  />
                  <Controller
                    name={`${fieldName}.role`}
                    control={control}
                    defaultValue={field.role}
                    render={(props) => (
                      <LabeledSelect
                        label="Role"
                        options={roleOptions}
                        isClearable={false}
                        onChange={props.onChange}
                        hasError={errors.users && errors.users[index]?.role}
                        value={props.value}
                      />
                    )}
                  />
                  <Button
                    sx={
                      fields.length === 1
                        ? {
                          visibility: 'hidden',
                          width: '120px',
                        }
                        : {
                          display: 'flex',
                          alignItems: 'center',
                          width: '120px',
                          height: '30px',
                          backgroundColor: 'bg.300',
                        }
                    }
                    isDisabled={fields.length === 1}
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <Icons.Discard
                      as="div"
                      sx={{ marginLeft: 'auto' }}
                      size={25}
                    />
                  </Button>
                </Flex>
              );
            })}
            <Flex
              sx={{
                marginTop: '35px!important',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={() => append(
                  {
                    email: '',
                    firstName: '',
                    lastName: '',
                    role: { value: '', label: '' },
                  },
                )}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '120px',
                  height: '30px',
                  color: 'primary',
                  backgroundColor: 'bg.0',
                }}
              >
                <Text
                  sx={{
                    fontWeight: 'bold',
                    color: 'primary',
                  }}
                >
                  + Add more
                </Text>
              </Button>

              <ButtonWithIcon
                isLoading={isLoading}
                type="submit"
                sx={{ width: '200px', borderRadius: '4px' }}
                variant="primary"
                icon={<Icons.SendIcon sx={{ fill: 'bg.0' }} />}
              >
                Send Invites
              </ButtonWithIcon>
            </Flex>
          </Form>
        </ShouldRender>
      </Container>
    </Flex>
  );
};
