import { authorize, putRequest } from 'utils/http';
import { Environment } from 'global/environment';
import { mapDataForBackend } from './mapData';

export async function updateUserRoleAPI(user) {
  return putRequest(
    `${Environment.BACKEND_API}/admin/users/${user.userId}/update/role`,
    mapDataForBackend(user),
    await authorize(),
  );
}
