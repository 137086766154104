import React, { useState } from 'react';
import { Container, Flex, LabeledSection } from 'components/layout';
import * as Icons from 'components/icons';
import { useSearchProducts } from 'services/search-products';
import { ScrollIntoView } from 'components/ScrollIntoView';
import { CardServiceHandler } from 'components/CardServiceHandler';
import { TabMenu } from 'components/menus';
import { CustomerMaintenanceCard } from 'pages/Home/components/Customer/components/CustomerMaintenanceCard';
import {
  CustomerCard,
  CustomerInfoCard,
  CustomerProductCard,
  CustomerCardHeader, CustomerServiceCard, CustomerCardPrimaryInfo, CustomerInfoCardHeader,
} from './components';

const customerTabs = [
  {
    title: 'Overview',
    isDisabled: false,
  },
  {
    title: 'Details',
    isDisabled: true,
  }, 
  {
    title: 'Products',
    isDisabled: true,
  },
  {
    title: 'Services',
    isDisabled: true,
  },
  {
    title: 'Balance',
    isDisabled: true,
  }, 
  {
    title: 'Invoice data',
    isDisabled: true,
  },
  {
    title: 'Account',
    isDisabled: true,
  },
  {
    title: 'History',
    isDisabled: true,
  },
];

export const Customer = ({ selectedCustomer, setSelectedCustomer }) => {
  // Only this user 199806057197 has hardcoded data for Products. TODO: remove once products are in BE
  // const registrationNumber = 199806057197;
  const registrationNumber = selectedCustomer?.identifier;
  const { products, isLoading, isError, isEmpty } = useSearchProducts({ registrationNumber });

  const [shouldEditCustomerInfo, setShouldEditCustomerInfo] = useState(false);

  return (
    <ScrollIntoView shouldScroll={selectedCustomer}>
      <LabeledSection
        icon={<Icons.Card size={27} />}
        label="Customer"
      >
        <TabMenu tabs={customerTabs} />

        <Container variant="section.content">
          <Flex
            flexDirection="column"
            gap="40px"
            sx={{
              height: 'fit-content',
            }}
          >

            <CustomerCard>
              <CustomerInfoCardHeader
                shouldEditCustomerInfo={shouldEditCustomerInfo}
                setShouldEditCustomerInfo={setShouldEditCustomerInfo}
              />

              <CustomerCardPrimaryInfo info={`ID Number: ${selectedCustomer?.customerNumber}`} />

              <CustomerInfoCard
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
                shouldEditCustomerInfo={shouldEditCustomerInfo}
                setShouldEditCustomerInfo={setShouldEditCustomerInfo}
              />
            </CustomerCard>

            <CustomerCard>
              <CustomerCardHeader category="Products" />

              <CustomerCardPrimaryInfo info="Status: Active" />

              <CardServiceHandler
                isLoading={isLoading}
                isError={isError}
                errorMessage="Error fetching products"
                isEmpty={isEmpty}
                emptyPayloadMessage="No products found for this customer"
              >
                {products.map((product, index) => (
                  <CustomerProductCard
                    key={index}
                    product={product}
                  />
                ))}
              </CardServiceHandler>

            </CustomerCard>

            <CustomerCard>
              <CustomerCardHeader category="Services" />
              <CustomerCardPrimaryInfo info="Maintenance" />
              <CardServiceHandler
                isEmpty={!selectedCustomer?.orders.length}
                emptyPayloadMessage="No orders found for this customer"
              >
                <CustomerMaintenanceCard orders={selectedCustomer?.orders} />
              </CardServiceHandler>
            </CustomerCard>

            <CustomerCard isDisabled>
              <CustomerCardHeader category="Services" />
              <CustomerCardPrimaryInfo info="Status: | Access point: 1/24 | Switch: SI-LDN" />
              <CustomerServiceCard />
            </CustomerCard>
          </Flex>
        </Container>
      </LabeledSection>
    </ScrollIntoView>
  );
};

