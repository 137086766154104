import * as yup from 'yup';
import { uniqueProperty } from 'utils/validation/uniqueProperty';

yup.addMethod(yup.array, 'uniqueProperty', uniqueProperty);

export const fieldsSchema = yup.object().shape({
  users: yup
    .array()
    .of(
      yup.object().shape({
        email: yup.string().email().required(),
        name: yup.string(),
        surname: yup.string(),
        role: yup.object().shape({
          value: yup.string().required(),
          label: yup.string().required(),
        }),
      }),
    )
    .uniqueProperty('email', 'Email must be unique'),
});
