import React from 'react';
import { IconButton } from '../IconButton';

export const Helpcenter = ({ size = 15.447, sx }) => (
  <IconButton
    as="div"
    sx={sx}
  >
    <svg
      style={{ width: size }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15.447 15.447"
    >
      <path className="HelpcenterA" d="M7.724,0a7.724,7.724,0,1,0,7.724,7.724A7.724,7.724,0,0,0,7.724,0Zm6.068,5.578a.161.161,0,0,1-.151.215H11.159a.163.163,0,0,1-.138-.077A3.894,3.894,0,0,0,9.734,4.428a.163.163,0,0,1-.079-.139V1.807a.162.162,0,0,1,.215-.152,6.469,6.469,0,0,1,3.922,3.923ZM7.724,10.3A2.575,2.575,0,1,1,10.3,7.724,2.575,2.575,0,0,1,7.724,10.3ZM5.578,1.655a.162.162,0,0,1,.215.152V4.289a.162.162,0,0,1-.077.138A3.894,3.894,0,0,0,4.428,5.714a.163.163,0,0,1-.14.079H1.807a.162.162,0,0,1-.133-.064.159.159,0,0,1-.02-.147A6.469,6.469,0,0,1,5.578,1.655ZM1.655,9.87a.16.16,0,0,1,.151-.215H4.289a.162.162,0,0,1,.138.077,3.894,3.894,0,0,0,1.287,1.287.162.162,0,0,1,.077.138v2.482a.161.161,0,0,1-.215.151,6.469,6.469,0,0,1-3.92-3.92ZM9.87,13.792a.161.161,0,0,1-.215-.151V11.159a.162.162,0,0,1,.077-.138,3.894,3.894,0,0,0,1.287-1.287.162.162,0,0,1,.138-.077h2.482a.16.16,0,0,1,.129.068.158.158,0,0,1,.02.146A6.469,6.469,0,0,1,9.87,13.792Z"/>
    </svg>
  </IconButton>
);
