import React, { useState } from 'react';
import { Container, Flex, LabeledSection } from 'components/layout';
import * as Icons from 'components/icons';
import { ButtonWithIcon } from 'components/form-elements';
import { Table } from 'components/Table';
import { CardServiceHandler } from 'components/CardServiceHandler';
import { UsersTabMenu } from 'components/menus';
import { Add } from 'components/icons';
import { SearchBar } from 'components/SearchBar';
import { Routing } from 'global/routing';
import { useRedirect } from 'utils/redirect';
import { useUsers } from 'services/users';
import { columns, mapDataToAccessor } from './tableData';

export const Users = () => {

  const { users, isLoading } = useUsers();
  const [activeTab, setActiveTab] = useState('active');
  const [keyword, setKeyword] = useState('');

  const { onRedirect } = useRedirect(Routing.INVITE_USERS_PAGE);

  const initialUsers = users?.filter(item => item.status === activeTab)
    .map(user => mapDataToAccessor(user))
    .sort((a, b) => -b.username.localeCompare(a.username));

  const data = !keyword ? initialUsers : initialUsers.filter(
    user => user.username.toLowerCase().includes(keyword.toLowerCase())
      || user.email.toLowerCase().includes(keyword.toLowerCase())
      || user.role.toLowerCase().includes(keyword.toLowerCase())
      || user.tenantId.toLowerCase().includes(keyword.toLowerCase()),
  );

  return (
    <Flex>
      <Container variant="page.content">
        <LabeledSection
          icon={<Icons.Users />}
          label="Users"
        >
          <Flex
            gap="10px"
            justifyContent="flex-end"
            sx={{
              marginBottom: '12px',
              width: '100%',
            }}
          >
            <SearchBar 
              keyword={keyword}
              setKeyword={setKeyword}
              placeholder="Search by user"
            />

            <ButtonWithIcon
              onClick={() => onRedirect()}
              sx={{
                minWidth: '170px',
                gap: '10px',
              }}
            >
              <Add />
              Invite users
            </ButtonWithIcon>
          </Flex>
          
          <UsersTabMenu
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />

          <CardServiceHandler
            isLoading={isLoading}
            isEmpty={!data.length}
            emptyPayloadMessage="No users to display"
          >
            <Table
              data={data}
              columns={columns}
              showExportTable={false}
              showToggleColumns={false}
              showTotalResults={false}
              typeOrder
            />
          </CardServiceHandler>
        </LabeledSection>
      </Container>
    </Flex>
  ); 
};

