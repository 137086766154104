import React from 'react';
import { TextArea } from 'components/form-elements';

const editableTextFieldSx = {
  padding: '3px',
  height: '25px',
  fontSize: '14px',
  color: 'bg.800',
  resize: 'none',

  '&:disabled': {
    height: '100%',
    border: 'none',
    background: 'bg.0',
    padding: '0px',
    overflow: 'hidden',
  },
};

export const EditableTextField = ({ hasError, forwardRef, ...props }) => (
  <TextArea
    sx={{
      ...editableTextFieldSx,
      borderColor: hasError && 'error',
      outlineColor: hasError && 'error',
    }}
    forwardRef={forwardRef}
    {...props}
  />
);
