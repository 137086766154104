export const styles = {
  popupModal: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '520px',
    gap: '30px',
    padding: '30px 30px 20px',
    backgroundColor: 'bg.200',
    borderRadius: '26px',
  },

  modalActions: {
    alignContent: 'center',
    justifyContent: 'space-between',
    gap: '5px',
    width: '100%',
    padding: '24px 24px 10px',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: 'bg.600',
  },
};
